import Axios from "axios";

const serverUrl = `${process.env.REACT_APP_SERVER_URL}`;

const create = async ({ body, token }) => {
  const endPoint = "/maintenance/create";
  const { data, status } = await Axios({
    method: "post",
    baseURL: `${serverUrl}${endPoint}`,
    data: {
      ...body,
    },
    headers: { Authorization: `Bearer ${token}` },
    responseType: "application/json",
  });
  return { data, status };
};
const deleteById = async ({ body, token }) => {
  const endPoint = "/maintenance/deleteById";
  const { data, status } = await Axios({
    method: "post",
    baseURL: `${serverUrl}${endPoint}`,
    data: {
      ...body,
    },
    headers: { Authorization: `Bearer ${token}` },
    responseType: "application/json",
  });
  return { data, status };
};
const getByMachineId = async ({ machineId, token }) => {
  const endPoint = "/maintenance/getByMachineId";
  const { data, status } = await Axios({
    method: "post",
    baseURL: `${serverUrl}${endPoint}`,
    data: {
      machineId,
    },
    headers: { Authorization: `Bearer ${token}` },
    responseType: "application/json",
  });
  return { data, status };
};

export { create, deleteById, getByMachineId };
