import React, { useState } from "react";
import User from "../api/user";
import AppBar from "../components/AppBar";
import AppBottomNavigation from "../components/BottomNavigation";
import Bookings from "./Bookings";
import Calendar from "./Calendar";
import Catalog from "./Catalog";
import ActionModal from "../components/ActionModal";
import { Box } from "@mui/material";
import { setMembers } from "../redux/actions";
import { useDispatch, useSelector } from "react-redux";

const Home = () => {
  const { user } = useSelector(({ slice }) => slice?.value);
  const { GetByCompany } = User || {};
  const [localProps, setLocalProps] = useState({
    isLoading: true,
    screen: 0,
  });
  const [screenIndex, setScreenIndex] = useState(0);

  const dispatch = useDispatch();

  const constructor = async () => {
    const { token } = user || {};
    let { data: _members, status } = await GetByCompany({ token });
    dispatch(
      setMembers(
        _members?.sort((a, b) => (a?.firstName > b?.firstName ? 1 : -1))
      )
    );
    status === 200 &&
      setLocalProps({
        ...localProps,
        isLoading: false,
      });
  };
  localProps.isLoading && constructor();

  const screens = {
    0: <Calendar user={user} />,
    1: <Catalog />,
    2: <Bookings />,
    // 2: <InAndOut />,
  };

  // useEffect(() => constructor(), [screenIndex]);

  return (
    <>
      <Box
        style={{ position: "sticky", zIndex: 10, top: 0, right: 0, left: 0 }}
      >
        <AppBar />
      </Box>
      {screens[screenIndex]}
      <AppBottomNavigation
        screenIndex={screenIndex}
        setScreenIndex={(index) => setScreenIndex(index)}
      />
      <ActionModal />
    </>
  );
};
export default Home;
