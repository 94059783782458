import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  FormControlLabel,
  IconButton,
  MenuItem,
  Select,
  Switch,
  TextField,
  Toolbar,
  Typography,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import Booking from "../api/booking";
import Machine from "../api/machine";
import Colors from "../constants/colors.json";
import Language from "../constants/language_fr.json";
import Styles from "../constants/styles.json";
import ActivityIndicatorContext from "../Contexts/ActivityIndicatorContext";
import LokaActivityIndicator from "./LokaActivityIndicator";
import LokaDatePicker from "./LokaDatePicker";
import InputLabel from "@mui/material/InputLabel";
import { getDiscountList, getDiscountPrices } from "../globals";
import MachineApi from "../api/machine";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import MultipleDesignationInput from "./MultipleDesignationInput";
import { useSelector } from "react-redux";

const AddBooking = ({
  discountGrid = [],
  handleClose,
  handleMergeBooking,
  initialBooking,
  role,
  setSnackBar,
}) => {
  const { setActivityIndicator } = useContext(ActivityIndicatorContext);
  const { company, members, user } = useSelector(({ slice }) => slice.value);
  const [booking, setBooking] = useState(initialBooking);
  const [discount, setDiscount] = useState({
    ...initialBooking?.rentalPrices?.discountList?.[0],
  });
  const [isEditMode, setIsEditMode] = useState(false);

  const {
    checker,
    createdAt,
    createdBy,
    delivery,
    departureAgency,
    equipmentsSelected,
    id: idBooking,
    machine,
    rentalPrices,
    servicesSelected,
  } = booking || {};

  const { benefits, equipments, rentMode } = machine || {};

  const [localProps, setlocalProps] = useState({ loading: true });

  const constructor = async () => {
    const { ConfigBooking, GetAllContracts } = Booking || {};
    const { rentMode = {} } = await MachineApi.Config({
      category: initialBooking?.machine?.machine?.category,
      token: user?.token,
    });
    const { ConfigKeyLabelConverter } = Machine || {};
    const { customer } = await ConfigBooking({
      token: user.token,
    });
    const _contracts = await GetAllContracts({
      idMachine: initialBooking?.machine?.id,
      token: user.token,
    });
    const response = await ConfigKeyLabelConverter({
      category: initialBooking?.machine?.machine?.category,
      token: user.token,
    });

    setBooking(initialBooking);
    setlocalProps({
      bookingConfig: { customerConfig: customer },
      contracts: _contracts,
      keyLabelConverter: response,
      loading: false,
      machineUnity: rentMode.machineUnity,
    });
  };

  const { bookingConfig, contracts, keyLabelConverter, loading, machineUnity } =
    localProps || {};

  const { agencies: cieAgencies = [] } = company;

  useEffect(() => {
    constructor();
  }, []);

  const { customerConfig } = bookingConfig || {};

  const _excludeDates = contracts?.length
    ? contracts
        .filter(
          (contract) =>
            !(role === "updateBooking" && contract.id === initialBooking.id)
        )
        .map((contract) => contract?.rentalPeriod || [])
        .flat()
    : [];

  const _rentMode =
    keyLabelConverter && rentMode
      ? ["unityPrice", "dayPrice"]
          .map((key) => {
            return {
              key,
              label: keyLabelConverter[key],
              value: rentMode?.[key],
              isDisabled: rentMode?.[key] ? false : true,
            };
          })
          .sort((a, b) => (a.isDisabled - b?.disabled > 0 ? 1 : -1))
      : [];

  const _servicesSelected =
    keyLabelConverter && benefits
      ? Object.entries(benefits).map(([key, value]) => {
          return {
            key,
            label: keyLabelConverter[key],
            value,
            sigle: `€`,
          };
        })
      : [];

  const _equipmentsSelected =
    keyLabelConverter && equipments
      ? Object.entries(equipments).map(([key, value]) => {
          return {
            key,
            label: keyLabelConverter[key],
            value: value[rentalPrices.key],
            sigle: `€ ${Language.addBooking[rentalPrices.key]}`,
          };
        })
      : [];

  const handleChangeDate = (rentalPeriod) => {
    const { key } = rentalPrices;
    const isDayMode = key === "dayPrice";
    const _quantity = String((rentalPeriod || []).length);
    isDayMode
      ? setBooking({
          ...booking,
          rentalPrices: { ...rentalPrices, quantity: _quantity },
          rentalPeriod,
        })
      : setBooking({ ...booking, rentalPeriod });
  };

  const handleCreateUpdateBooking = async () => {
    const { Create, Update } = Booking || {};
    setActivityIndicator({
      isOpen: true,
      message:
        role === "addBooking"
          ? Language.addBooking.addBookingInProgress
          : Language.addBooking.updateBookingInProgress,
    });
    const _bookingToUpdate = {
      ...booking,
      createdAt,
      createdBy,
      id: idBooking,
    };
    if (role === "addBooking") {
      const status = await Create({
        booking,
        token: user.token,
      });
      if (status === 200) {
        handleResetClose();
        setSnackBar({
          isOpen: true,
          message: Language.addBooking.addBookingSuccess,
          status: "success",
        });
        setActivityIndicator({ isOpen: false, message: null });
      } else {
        setActivityIndicator({ isOpen: false, message: null });
      }
    } else if (role === "updateBooking") {
      const { data, status } = await Update({
        booking: _bookingToUpdate,
        token: user.token,
      });
      if (status === 200) {
        const { booking } = data || {};
        handleMergeBooking({ booking });
        handleResetClose();
        setSnackBar({
          isOpen: true,
          message: Language.addBooking.updateBookingSuccess,
          status: "success",
        });
        setActivityIndicator({ isOpen: false, message: null });
      } else {
        setActivityIndicator({ isOpen: false, message: null });
      }
    }
  };

  const handleInputChange = ({ bookingKey, target, key }) => {
    const _booking = bookingKey
      ? {
          ...booking,
          [bookingKey]: {
            ...booking[bookingKey],
            [key]: target?.value,
          },
        }
      : {
          ...booking,
          [key]: target?.value,
        };
    key && setBooking(_booking);
  };

  const handleRentModeChange = ({ key }) => {
    const _discountList = getDiscountList({
      contractType: "standard",
      discountGrid,
      rentalPricesKey: key,
    });
    const _booking = {
      ...booking,
      rentalPrices: {
        contractType: "standard",
        discountList: _discountList,
        key,
        [key]: rentMode[key],
        quantity:
          key === "dayPrice" ? String(booking.rentalPeriod.length) : "0",
      },
    };
    setBooking(_booking);
  };

  const handleChangeContractType = ({ target }) => {
    const { value } = target || {};
    const _discountList = getDiscountList({
      contractType: value,
      discountGrid,
      rentalPricesKey: booking?.rentalPrices?.key,
    });
    const _booking = {
      ...booking,
      rentalPrices: {
        ...booking?.rentalPrices,
        contractType: value,
        discountList: _discountList,
      },
    };
    setBooking(_booking);
  };
  const handleSelectChange = ({ key, value }) => {
    const _booking = {
      ...booking,
      [key]: value,
    };
    setBooking(_booking);
  };

  const handleResetClose = () => {
    handleClose();
  };

  const handleSetDiscount = () => {
    setBooking({
      ...booking,
      rentalPrices: {
        ...booking?.rentalPrices,
        discountList: [discount],
      },
    });
    handleCloseDiscount();
  };

  const handleCloseDiscount = () => {
    setDiscount({ key: "", value: "", discountComment: "" });
    setIsEditMode(false);
  };

  const handleSwitchOnEditMode = () => {
    const _discount = booking?.rentalPrices?.discountList?.[0] || {};
    const handleOpenDiscountModal = () => {
      setDiscount(_discount);
      setIsEditMode(true);
    };
    parseFloat(grossAmount) > 0
      ? handleOpenDiscountModal()
      : setSnackBar({
          isOpen: true,
          message: Language.addBooking.noGrossAmount,
          status: "error",
        });
  };

  const handleSwitchDiscountChange = ({ key }) => {
    return key
      ? setBooking({
          ...booking,
          rentalPrices: {
            ...booking?.rentalPrices,
            contractType: key,
          },
        })
      : null;
  };
  const handleSwitchServicesChange = ({ group, key, value }) => {
    const onChecking = booking[group][key] ? false : true;
    const _booking = onChecking
      ? { ...booking, [group]: { ...booking[group], [key]: value } }
      : { ...booking, [group]: { ...booking[group], [key]: false } };
    setBooking(_booking);
  };

  const handleSwitchEquipmentsChange = ({ group, key, value }) => {
    const onChecking = booking[group][key] ? false : true;
    const _booking = onChecking
      ? { ...booking, [group]: { ...booking[group], [key]: equipments[key] } }
      : { ...booking, [group]: { ...booking[group], [key]: false } };
    setBooking(_booking);
  };

  const Summary = () => {
    const { machine, machineFeatures } = booking.machine || {};
    const { agency, bondPrice, brand, category, model, valuePrice, year } =
      machine || {};
    const { horsePower } = machineFeatures || {};
    const { distance, key: deliveryKey, kmPrice, totalAmount } = delivery || {};
    const _customerSummary = [
      "name",
      "owner",
      "address",
      "city",
      "phone",
      "email",
    ]
      .map((item) =>
        booking?.customer && booking?.customer[item]
          ? booking?.customer[item]
          : null
      )
      .filter((el) => el);

    const _machineSummary = [
      {
        key: "description",
        value: `${Language.categoryConverter[category]} ${brand} ${model}`,
      },
      { key: "agency", title: Language.addBooking.agency, value: agency },
      { key: "year", title: Language.addBooking.year, value: year },
      {
        key: "horsePower",
        title: Language.addBooking.horsePower,
        value: horsePower,
        sigle: "ch",
      },
      {
        key: "bondPrice",
        title: Language.addBooking.bondPrice,
        value: bondPrice,
        sigle: "€",
      },
      {
        key: "valuePrice",
        title: Language.addBooking.valuePrice,
        value: valuePrice,
        sigle: "€",
      },
    ];

    const _rentalPricesLabel = {
      key: "rentalPricesLabel",
      value: `${rentalPrices.quantity || "0"} ${
        Language.rentalPrices[rentalPrices.key]
      } x ${rentalPrices[rentalPrices.key]}€ = ${String(
        parseFloat(rentalPrices[rentalPrices.key]).toFixed(2) *
          parseFloat(rentalPrices.quantity).toFixed(2)
      )}€`,
    };

    const _deliveryLabels = {
      agencyPickUp: {
        key: "agencyPickUp",
        value: `${Language.addBooking.agencyPickUp}: ${agency}`,
      },
      toDeliver: {
        key: "toDeliver",
        value: `${Language.addBooking.toDeliver}: ${distance}${Language.addBooking.kms} x ${kmPrice}${Language.addBooking.eurosPerKm} = ${totalAmount}€`,
      },
      toDeliverRoundTrip: {
        key: "toDeliverRoundTrip",
        value: `${Language.addBooking.toDeliverRoundTrip}: ${distance}${Language.addBooking.kms} x ${kmPrice}${Language.addBooking.eurosPerKm} = ${totalAmount}€`,
      },
    };

    const _servicesLabels = Object.entries(servicesSelected)
      ?.map(([key, value]) => {
        return value
          ? { key, value: `${keyLabelConverter[key]} = ${value}€` }
          : null;
      })
      .filter((service) => service);

    const _equipmentsSelectedLabels = Object.entries(equipmentsSelected)
      .map(([key, value]) => {
        return value
          ? {
              key: key,
              value: `${keyLabelConverter[key]} : ${rentalPrices?.quantity} ${
                Language.rentalPrices[rentalPrices.key]
              } x ${value[rentalPrices?.key]}€`,
            }
          : null;
      })
      .filter((equipment) => equipment);

    const _totalRentalPrices =
      parseFloat(rentalPrices.quantity || "0") *
      parseFloat(rentalPrices[rentalPrices.key] || "0");

    const _totalDelivery = delivery?.totalAmount
      ? parseFloat(delivery.totalAmount)
      : 0;

    const getTotalServices = () => {
      const _totalServices = Object.values(servicesSelected).filter(
        (service) => service
      );

      return _totalServices.length
        ? _totalServices
            .filter((service) => service)
            .reduce((value, acc) => parseFloat(value) + parseFloat(acc), 0)
        : 0;
    };

    const getTotalEquipments = () => {
      const _totalEquipments = Object.values(equipmentsSelected).filter(
        (equipment) => equipment
      );
      return _totalEquipments.length
        ? _totalEquipments
            .map((value) =>
              value
                ? parseFloat(rentalPrices.quantity) *
                  parseFloat(value[rentalPrices.key])
                : null
            )
            .reduce((curr, acc) =>
              (parseFloat(curr) + parseFloat(acc)).toFixed(2)
            )
        : 0;
    };

    const _total = [
      _totalRentalPrices,
      _totalDelivery,
      getTotalServices(),
      getTotalEquipments(),
    ].reduce((curr, acc) => (parseFloat(curr) + parseFloat(acc)).toFixed(2));

    return (
      <>
        <Box
          display="flex"
          flexDirection="row"
          alignItems="center"
          sx={Styles.bottomSeparator}
        >
          <Box flex={1}>
            {_customerSummary.map((item) => (
              <Typography key={item}>{item || ""}</Typography>
            ))}
          </Box>
          <Box flex={1}>
            {[_rentalPricesLabel, _deliveryLabels[deliveryKey]].map((item) => (
              <Typography key={item.key}>{item.value}</Typography>
            ))}
            {_servicesLabels.map((item) => (
              <Typography key={item.key}>{item.value}</Typography>
            ))}
            {_equipmentsSelectedLabels.map((item) => (
              <Typography key={item.key}>{item.value}</Typography>
            ))}
            <Typography
              sx={{
                borderTopStyle: "solid",
                borderTopWidth: 1,
                borderTopColor: Colors.greyHighShaded,
                marginTop: 1,
                paddingTop: 1,
                fontWeight: "bold",
              }}
            >{`${Language.addBooking.total}: ${String(
              _total
            )}€ H.T`}</Typography>
          </Box>
        </Box>
        <Box
          display="flex"
          flexDirection="row"
          alignItems="center"
          sx={{
            padding: 4,
          }}
        >
          <Box flex={1}>
            {_machineSummary.map(
              (item) =>
                item?.value && (
                  <Box display="flex" flexDirection="row" key={item.key}>
                    {item?.title ? (
                      <Typography
                        marginRight={1}
                      >{`${item?.title} : `}</Typography>
                    ) : null}
                    <Typography fontWeight="bold">{`${item.value}${
                      item?.sigle || ""
                    }`}</Typography>
                  </Box>
                )
            )}
          </Box>
          <Box flex={1}>
            <LokaDatePicker
              isEditable={false}
              rentalPeriod={booking.rentalPeriod}
            />
          </Box>
        </Box>
      </>
    );
  };

  const steps = [
    {
      helpers: "1. Mode de réservation",
      key: "rentalPrices",
      name: "Mode de location",
      type: "rentalPrices",
    },
    {
      helpers: "2. Planning de réservation",
      key: "rentalPeriod",
      name: "Dates",
      type: "calendar",
    },
    {
      helpers: "3. Coordonnées de votre client",
      key: "customer",
      name: "Client",
      inputsList: customerConfig?.items,
      type: "text",
    },
    {
      helpers: "4. Equipements réservés",
      key: "equipmentsSelected",
      name: "Equipements",
      inputsList: _equipmentsSelected,
      type: "switchEquipments",
    },
    {
      helpers: "5. Services réservés",
      key: "servicesSelected",
      name: "Services",
      inputsList: _servicesSelected,
      type: "switchServices",
    },
    {
      helpers: "6. Suppléments",
      key: "bookingCosts",
      name: "Suppléments",
      type: "bookingCosts",
    },
    {
      helpers: "7. Commentaires",
      key: "comment",
      name: "Commentaires",
      type: "multiline",
    },
    {
      helpers: "8. Livraison",
      key: "delivery",
      name: "Livraison",
      type: "directionMapView",
    },
    {
      helpers: "9. Etat des lieux",
      key: "checker",
      name: "Etat des lieux",
      type: "checkerSelect",
    },
  ];

  const { key: discountKey, value: discountValue } =
    booking?.rentalPrices?.discountList?.[0] || {};

  const canSetDiscount = discountGrid?.some(
    ({ key, grid }) =>
      key === booking?.rentalPrices?.contractType &&
      Object.values(grid).some((el) => el)
  );

  const standardGrid = [
    {
      stdGridLabel: `Remise en ${machineUnity}(s)`,
      stdDisKey: "unity",
      stdDisValue: discount?.key === "unity" ? discount?.value : "",
      isMultiline: false,
    },
    {
      stdGridLabel: `Remise en jour(s)`,
      stdDisKey: "day",
      stdDisValue: discount?.key === "day" ? discount?.value : "",
      isMultiline: false,
    },
    {
      stdGridLabel: `Remise en %`,
      stdDisKey: "percentage",
      stdDisValue: discount?.key === "percentage" ? discount?.value : "",
      isMultiline: false,
    },
    {
      stdGridLabel: `Remise en €`,
      stdDisKey: "default",
      stdDisValue: discount?.key === "default" ? discount?.value : "",
      isMultiline: false,
    },
    {
      stdDisKey: "discountComment",
      stdDisValue: discount?.discountComment || "",
      isMultiline: true,
    },
  ]?.filter(({ stdDisKey }) =>
    booking?.rentalPrices?.key === "dayPrice"
      ? stdDisKey !== "unity"
      : stdDisKey !== "day"
  );

  const { grossAmount, netDiscount, netAmount } = getDiscountPrices({
    dayPrice: booking?.rentalPrices?.dayPrice || 0,
    delivery: booking?.delivery,
    discountKey: booking?.rentalPrices?.discountList?.[0]?.key,
    discountValue: booking?.rentalPrices?.discountList?.[0]?.value || 0,
    equipmentsSelected: booking?.equipmentsSelected,
    rentalPrices: booking?.rentalPrices,
    servicesSelected: booking?.servicesSelected,
    unityPrice: booking?.rentalPrices?.unityPrice || 0,
  });

  return loading ? null : (
    <>
      <Dialog
        fullWidth={true}
        maxWidth="lg"
        open={true}
        onClose={() => handleResetClose()}
      >
        <Box sx={{ backgroundColor: Colors.grey }}>
          <Toolbar>
            <IconButton
              edge="start"
              onClick={() => handleResetClose()}
              aria-label="close"
            >
              <CloseIcon sx={{ color: Colors.white }} />
            </IconButton>
            <Typography variant="h6" color={Colors.white}>
              {role === "addBooking"
                ? Language.addBooking.addBookingMainTitle
                : Language.addBooking.updateBookingMainTitle}
            </Typography>
          </Toolbar>
        </Box>
        <DialogContent>
          {steps.map(({ helpers, inputsList, key, name, type }) => (
            <Box key={key} flex={2} padding={5}>
              <Box
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
                sx={{
                  borderBottomWidth: 1,
                  borderBottomColor: Colors.greyShaded,
                  borderBottomStyle: "solid",
                }}
                paddingBottom={2}
              >
                <Box sx={{ flex: 1 }}>
                  <Typography variant="h6" color={Colors.green}>
                    {`${helpers}`}
                  </Typography>
                </Box>
              </Box>
              <Box
                style={{
                  display: "flex",
                  flexDirection: "row",
                  flexWrap: "wrap",
                  justifyContent: "center",
                }}
              >
                {type === "text" ? (
                  inputsList.map((input) => (
                    <Box padding={1} key={input.key}>
                      <TextField
                        size="small"
                        id={input.key}
                        label={input.label}
                        value={booking?.[key]?.[input.key]}
                        onChange={({ target }) =>
                          handleInputChange({
                            bookingKey: key,
                            target,
                            key: input.key,
                          })
                        }
                      />
                    </Box>
                  ))
                ) : type === "calendar" ? (
                  <Box
                    sx={{
                      padding: 5,
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                    }}
                  >
                    <LokaDatePicker
                      excludeDates={_excludeDates}
                      isEditable={true}
                      rentalPeriod={booking.rentalPeriod}
                      setRentalPeriod={(rentalPeriod) =>
                        handleChangeDate(rentalPeriod)
                      }
                    />
                  </Box>
                ) : type === "rentalPrices" ? (
                  <div style={{ flex: 1 }}>
                    <div style={{ flex: 1, display: "flex" }}>
                      <div style={{ flex: 1, display: "flex" }}>
                        {_rentMode?.map(({ key, label, isDisabled }) => {
                          const isActive = booking?.rentalPrices?.key === key;
                          const containerStyle = isActive
                            ? {
                                borderBottomColor: Colors.green,
                                borderBottomStyle: "solid",
                                borderBottomWidth: 2,
                                cursor: isDisabled ? "not-allowed" : "pointer",
                              }
                            : {
                                cursor: isDisabled ? "not-allowed" : "pointer",
                              };
                          const textStyle = {
                            color: isActive ? Colors?.green : Colors.greyShaded,
                          };
                          return (
                            <div
                              key={key}
                              onClick={() =>
                                !isDisabled
                                  ? handleRentModeChange({ key })
                                  : null
                              }
                              style={{
                                flex: 1,
                                padding: 5,
                                ...containerStyle,
                              }}
                            >
                              <p style={{ textAlign: "center", ...textStyle }}>
                                {label}
                              </p>
                            </div>
                          );
                        })}
                      </div>
                      <div style={{ flex: 2 }}></div>
                    </div>
                    <div
                      style={{ flex: 1, display: "flex", flexDirection: "row" }}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          alignItems: "center",
                          flex: 1,
                        }}
                      >
                        <div style={{ width: "100%" }}>
                          <InputLabel
                            variant="standard"
                            htmlFor="rentalPricesSelectInput"
                          >
                            Type de contrat
                          </InputLabel>
                          <Select
                            id="rentalPricesSelectInput"
                            size="small"
                            defaultValue="placeholder"
                            value={rentalPrices?.contractType}
                            onChange={handleChangeContractType}
                            sx={{ flex: 1 }}
                          >
                            <MenuItem
                              disabled
                              key="placeholder"
                              value="placeholder"
                            >
                              {Language.addBooking.selectRentMode}
                            </MenuItem>
                            {discountGrid.map(
                              ({ key: contractTypeKey, label }) => (
                                <MenuItem
                                  key={contractTypeKey}
                                  value={contractTypeKey}
                                >
                                  {label}
                                </MenuItem>
                              )
                            )}
                          </Select>
                        </div>
                      </div>
                      {rentalPrices?.key && (
                        <div
                          style={{
                            flex: 1,
                            display: "flex",
                            flexDirection: "column",
                            padding: 20,
                            justifyContent: "center",
                          }}
                        >
                          <TextField
                            type="number"
                            size="small"
                            id="rentalPrice"
                            label={
                              Language.addBooking[`${rentalPrices.key}Label`]
                            }
                            sx={{ width: "100%", marginTop: 1 }}
                            value={rentalPrices[rentalPrices.key]}
                            onChange={({ target }) =>
                              setBooking({
                                ...booking,
                                rentalPrices: {
                                  ...booking.rentalPrices,
                                  [rentalPrices.key]: target.value,
                                },
                              })
                            }
                          />
                          <TextField
                            type="number"
                            size="small"
                            id="rentalPriceQuantity"
                            label={
                              Language.addBooking[`${rentalPrices.key}Quantity`]
                            }
                            sx={{ width: "100%", marginTop: 1 }}
                            value={rentalPrices.quantity}
                            onChange={({ target }) =>
                              setBooking({
                                ...booking,
                                rentalPrices: {
                                  ...rentalPrices,
                                  quantity: target.value,
                                },
                              })
                            }
                          />
                        </div>
                      )}
                      {/* Dedicated section for discount edition */}
                      <div
                        style={{
                          flex: 1,
                          padding: 20,
                          display: "flex",
                          justifyContent: "center",
                          flexDirection: "column",
                        }}
                      >
                        <div>
                          {isEditMode ? (
                            <Dialog
                              maxWidth="sm"
                              open={true}
                              onClose={() => handleCloseDiscount()}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  gap: 10,
                                  padding: 20,
                                }}
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    paddingBottom: 2,
                                    marginBottom: 2,
                                    borderBottomColor: Colors.greyShaded,
                                    borderBottomWidth: 1,
                                    borderBottomStyle: "solid",
                                  }}
                                >
                                  <p>Ajouter une remise</p>
                                  <p
                                    onClick={() => handleCloseDiscount()}
                                    style={{ cursor: "pointer" }}
                                  >
                                    X
                                  </p>
                                </div>
                                {standardGrid?.map(
                                  ({
                                    isMultiline,
                                    stdGridLabel,
                                    stdDisKey,
                                    stdDisValue,
                                  }) => (
                                    <TextField
                                      multiline={isMultiline}
                                      minRows={isMultiline ? 4 : 1}
                                      type="number"
                                      key={stdDisKey}
                                      size="small"
                                      id={`input_${stdDisKey}`}
                                      label={stdGridLabel}
                                      sx={{ width: "100%", marginTop: 1 }}
                                      value={stdDisValue}
                                      onChange={({ target }) =>
                                        stdDisKey === "discountComment"
                                          ? setDiscount({
                                              ...discount,
                                              [stdDisKey]: target?.value,
                                            })
                                          : setDiscount({
                                              key: stdDisKey,
                                              value: target?.value,
                                              discountComment:
                                                discount?.discountComment || "",
                                            })
                                      }
                                    />
                                  )
                                )}
                                <div
                                  style={{
                                    alignSelf: "center",
                                    backgroundColor: Colors.green,
                                    padding: 5,
                                    borderRadius: 5,
                                    cursor: "pointer",
                                  }}
                                  onClick={() => handleSetDiscount()}
                                >
                                  <p
                                    style={{
                                      textAlign: "center",
                                      color: Colors.white,
                                    }}
                                  >
                                    Valider
                                  </p>
                                </div>
                              </div>
                            </Dialog>
                          ) : canSetDiscount ? (
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                              }}
                            >
                              <p
                                style={{
                                  color: Colors.grayText,
                                  textAlign: "center",
                                }}
                              >
                                {["unity"].includes(discountKey)
                                  ? `${discountValue} ${machineUnity}(s) offert(es)`
                                  : discountValue
                                  ? `${discountValue} ${Language?.addBooking?.discountLabels?.[discountKey]} offert(s)`
                                  : `Aucune remise appliquée`}
                              </p>
                              <ModeEditIcon
                                sx={{ color: Colors.green, cursor: "pointer" }}
                                onClick={() => handleSwitchOnEditMode()}
                              />
                            </div>
                          ) : (
                            <div>
                              <p
                                style={{
                                  color: Colors.greyShaded,
                                  textAlign: "center",
                                  flexWrap: "wrap",
                                }}
                              >{`Aucune remise ne peut être appliquée sur ce type de contrat.`}</p>
                            </div>
                          )}
                        </div>
                      </div>
                      <div
                        style={{
                          flex: 1,
                          display: "flex",
                          flexDirection: "column",
                          padding: 20,
                          justifyContent: "center",
                          alignItems: "flex-end",
                          gap: 5,
                        }}
                      >
                        {[
                          {
                            label: "Montant brut",
                            key: "grossAmount",
                            textStyle: {
                              color: Colors.greyShaded,
                              fontStyle: "normal",
                            },
                            value: grossAmount,
                          },
                          {
                            label: "Remise",
                            key: "netDiscount",
                            textStyle: {
                              color: Colors.greyShaded,
                              fontStyle: "normal",
                              fontSize: 12,
                              width: "80%",
                              textAlign: "right",
                              borderBottomColor: Colors.greyHighShaded,
                              borderBottomStyle: "solid",
                              borderBottomWidth: 1,
                            },
                            value: netDiscount,
                          },
                          {
                            label: "Montant net",
                            key: "netAmount",
                            textStyle: {
                              color: Colors.grayText,
                              fontWeight: "bold",
                            },
                            value: netAmount,
                          },
                        ].map(({ label, key, textStyle, value }) => (
                          <Typography
                            key={key}
                            style={textStyle}
                          >{`${label} : ${value || 0}€ HT`}</Typography>
                        ))}
                      </div>
                    </div>
                  </div>
                ) : type === "switchServices" ? (
                  inputsList?.length ? (
                    inputsList.map((input) => (
                      <Box sx={{ padding: 1 }} key={input.key}>
                        <FormControlLabel
                          control={
                            <Switch
                              color="secondary"
                              checked={!booking?.[key]?.[input.key] === false}
                              onChange={() =>
                                handleSwitchServicesChange({
                                  group: key,
                                  key: input.key,
                                  value: input.value,
                                })
                              }
                            />
                          }
                          label={`${input.label} (${input.value} ${input.sigle})`}
                        ></FormControlLabel>
                      </Box>
                    ))
                  ) : (
                    <Box>
                      <Typography
                        sx={{ color: Colors.greyShaded, fontStyle: "italic" }}
                      >
                        {Language.addBooking?.noServicesForThisMachine}
                      </Typography>
                    </Box>
                  )
                ) : type === "switchDiscount" ? (
                  inputsList?.length ? (
                    inputsList.map((input) => (
                      <Box sx={{ padding: 1 }} key={input.key}>
                        <FormControlLabel
                          control={
                            <Switch
                              color="secondary"
                              checked={
                                booking.rentalPrices?.contractType === input.key
                              }
                              onChange={() =>
                                handleSwitchDiscountChange({
                                  key: input.key,
                                })
                              }
                            />
                          }
                          label={`${input.label}`}
                        ></FormControlLabel>
                      </Box>
                    ))
                  ) : (
                    <Box>
                      <Typography
                        sx={{ color: Colors.greyShaded, fontStyle: "italic" }}
                      >
                        {Language.addBooking?.noServicesForThisMachine}
                      </Typography>
                    </Box>
                  )
                ) : type === "switchEquipments" ? (
                  inputsList?.length ? (
                    inputsList.map((input) => (
                      <Box sx={{ padding: 1 }} key={input.key}>
                        <FormControlLabel
                          control={
                            <Switch
                              color="secondary"
                              checked={!booking[key][input.key] === false}
                              onChange={() =>
                                handleSwitchEquipmentsChange({
                                  group: key,
                                  key: input.key,
                                  value: input.value,
                                })
                              }
                            />
                          }
                          label={`${input.label} (${input.value} ${input.sigle})`}
                        ></FormControlLabel>
                      </Box>
                    ))
                  ) : (
                    <Box>
                      <Typography
                        sx={{ color: Colors.greyShaded, fontStyle: "italic" }}
                      >
                        {Language.addBooking?.noEquipmentsForThisMachine}
                      </Typography>
                    </Box>
                  )
                ) : type === "multiline" ? (
                  <Box padding={1} width="80%">
                    <TextField
                      style={{ width: "100%" }}
                      multiline
                      rows={4}
                      size="small"
                      id={key}
                      label={name}
                      value={booking?.[key]?.text}
                      onChange={({ target }) =>
                        handleInputChange({
                          bookingKey: key,
                          target,
                          key: "text",
                        })
                      }
                    />
                  </Box>
                ) : type === "bookingCosts" ? (
                  <Box padding={1} width="80%">
                    <MultipleDesignationInput
                      inputList={booking?.bookingCosts}
                      onChange={(bookingCosts) =>
                        setBooking({ ...booking, bookingCosts })
                      }
                    />
                  </Box>
                ) : type === "directionMapView" ? (
                  <Box sx={{ padding: 1 }}>
                    <Select
                      style={{ flex: 1 }}
                      value={departureAgency}
                      onChange={({ target }) =>
                        handleSelectChange({
                          key: "departureAgency",
                          value: target.value,
                        })
                      }
                    >
                      <MenuItem disabled key="placeholder" value="placeholder">
                        {Language.addBooking.selectAgency}
                      </MenuItem>
                      {cieAgencies.map(({ name }) => (
                        <MenuItem key={name} value={name}>
                          {name}
                        </MenuItem>
                      ))}
                    </Select>
                  </Box>
                ) : type === "checkerSelect" ? (
                  <Box sx={{ padding: 1 }}>
                    <Select
                      style={{ flex: 1 }}
                      value={checker}
                      onChange={({ target }) =>
                        handleSelectChange({
                          key: "checker",
                          value: target.value,
                        })
                      }
                    >
                      <MenuItem disabled key="placeholder" value="placeholder">
                        {Language.addBooking.selectChecker}
                      </MenuItem>
                      {members?.map(({ firstName, id, lastName }) => (
                        <MenuItem key={id} value={id}>
                          {`${firstName} ${lastName}`}
                        </MenuItem>
                      ))}
                    </Select>
                  </Box>
                ) : type === "validation" ? (
                  <Box sx={{ padding: 1 }}>
                    <Summary />
                  </Box>
                ) : null}
              </Box>
            </Box>
          ))}

          <Box
            display="flex"
            flexDirection="row"
            justifyContent="space-around"
            flex={1}
          >
            <Button
              onClick={() => handleCreateUpdateBooking()}
              sx={{
                backgroundColor: Colors.green,
                color: Colors.white,
              }}
            >
              {Language.addBooking[role].toUpperCase()}
            </Button>
          </Box>
        </DialogContent>
      </Dialog>
      <LokaActivityIndicator />
    </>
  );
};

export default AddBooking;
