import { Grid } from "@mui/material";
import React, { useState } from "react";
import AddAgency from "./addAgency";
import AddDefaultCategories from "./addDefaultCategories";
import AddRequiredFields from "./addRequiredFields";
import ApiConnexions from "./apiConnexions";
import CompanyGroupList from "./CompanyGroupList";
import CompanyNotifications from "./companyNotifications";
import ImageSize from "./imageSize";
import { useDispatch, useSelector } from "react-redux";
import { setCompany } from "../../redux/actions";
export default function ConcessionParams({
  companyInfo,
  userInfo,
  setSnackBar,
  logInfo,
  setLoader,
  getLogInfo,
  setSnackbar,
  successSnackBar,
  failedSnackBar,
  setIsUserDetailOpen,
  setActivityIndicator,
  categories,
  requiredFields,
}) {
  const dispatch = useDispatch();
  const { company, user } = useSelector(({ slice }) => slice.value);
  const [expanded, setExpanded] = useState(null);
  return (
    <Grid
      container
      direction="row"
      align="center"
      justifyContent="center"
      alignItems="center"
      style={{ marginTop: "25px" }}
    >
      <Grid item xs={9} lg={9}>
        <AddAgency
          companyInfo={companyInfo}
          userInfo={userInfo}
          expanded={expanded}
          setExpanded={setExpanded}
          logInfo={logInfo}
          setLoader={setLoader}
          getLogInfo={getLogInfo}
          setSnackbar={setSnackbar}
          successSnackBar={successSnackBar}
          failedSnackBar={failedSnackBar}
          setIsUserDetailOpen={setIsUserDetailOpen}
          setSnackBar={(props) => setSnackBar(props)}
        />
        <ImageSize
          companyInfo={companyInfo}
          userInfo={userInfo}
          expanded={expanded}
          setExpanded={setExpanded}
          setSnackBar={(props) => setSnackBar(props)}
          setCompany={dispatch(setCompany)}
          setActivityIndicator={setActivityIndicator}
        />
        <AddDefaultCategories
          companyInfo={companyInfo}
          userInfo={userInfo}
          expanded={expanded}
          setExpanded={setExpanded}
          setSnackBar={(props) => setSnackBar(props)}
          setCompany={setCompany}
          setActivityIndicator={setActivityIndicator}
          categories={categories}
        />
        <CompanyGroupList
          company={company}
          expanded={expanded}
          setExpanded={setExpanded}
          setSnackBar={(props) => setSnackBar(props)}
          setCompany={setCompany}
          setActivityIndicator={setActivityIndicator}
          user={user}
        />
        <AddRequiredFields
          companyInfo={companyInfo}
          userInfo={userInfo}
          expanded={expanded}
          setExpanded={setExpanded}
          setSnackBar={(props) => setSnackBar(props)}
          setCompany={setCompany}
          setActivityIndicator={setActivityIndicator}
          requiredFieldsList={requiredFields}
        />
        <CompanyNotifications expanded={expanded} setExpanded={setExpanded} />
        <ApiConnexions
          companyInfo={companyInfo}
          userInfo={userInfo}
          expanded={expanded}
          setExpanded={setExpanded}
          setSnackBar={(props) => setSnackBar(props)}
          setCompany={setCompany}
          setActivityIndicator={setActivityIndicator}
        />
      </Grid>
    </Grid>
  );
}
