import React, { useContext, useState, useEffect } from "react";
import {
  AppBar,
  Box,
  Card,
  CardMedia,
  Toolbar,
  Typography,
  Slide,
  Tooltip,
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import LogoutIcon from "@mui/icons-material/Logout";
import SettingsIcon from "@mui/icons-material/Settings";
import MenuIcon from "@mui/icons-material/Menu";
import Language from "../constants/language_fr.json";
import LokaActivityIndicator from "./LokaActivityIndicator";
import ActivityIndicatorContext from "../Contexts/ActivityIndicatorContext";
import LokaSnackBar from "../components/LokaSnackBar";
import Category from "../api/category";
import User from "../api/user";
import Booking from "../api/booking";
import DialogBody from "./TabsBar";
import { setCompany, setUser } from "../redux/actions";
import { useDispatch, useSelector } from "react-redux";
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
export default function ButtonAppBar(props) {
  const dispatch = useDispatch();
  const { user } = useSelector(({ slice }) => slice?.value);

  const { setActivityIndicator } = useContext(ActivityIndicatorContext);
  const [isUserDetailOpen, setIsUserDetailOpen] = useState(false);
  const [categories, setCategories] = useState([]);
  const [requiredFields, setRequiredFields] = useState([]);
  const [bookingRestrictions, setBookingRestrictions] = useState([]);
  const [localProps, setLocalProps] = useState({
    isLoading: true,
  });

  const constructor = async () => {
    const { token } = user || {};
    setActivityIndicator({
      isOpen: true,
      message: Language.appBar.loading,
    });
    const { GetAllCategories, ConfigCategory } = Category || {};
    const { ConfigBooking } = Booking || {};

    const { customer } = await ConfigBooking({
      token: token,
    });
    setBookingRestrictions(customer?.items);
    const _categories = await GetAllCategories({ token });
    const { machine, machineFeatures } = await ConfigCategory({ token });
    const _requiredFields = [...machine.items, ...machineFeatures.items].map(
      ({ key, label }) => {
        return { key, label };
      }
    );

    setRequiredFields(_requiredFields);
    setCategories(_categories);
    setLocalProps({
      ...localProps,
      isLoading: false,
    });
    setActivityIndicator({
      isOpen: false,
      message: null,
    });
  };
  useEffect(() => {
    constructor();
  }, []);
  const logOut = () => {
    dispatch(setUser(null));
    dispatch(setCompany(null));
    localStorage.removeItem("user");
  };
  return (
    <Box sx={{ flex: 1 }}>
      <AppBar position="static">
        <Toolbar sx={{ justifyContent: "space-between" }}>
          <Box
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <IconButton
              size="large"
              edge="start"
              color="inherit"
              aria-label="menu"
              sx={{ mr: 2 }}
            >
              <MenuIcon />
            </IconButton>
            <Card sx={{ maxWidth: 150 }}>
              <CardMedia
                component="img"
                //height="40"
                width="100"
                image="https://loka-prod.s3.eu-west-3.amazonaws.com/CONFIGURATION/logo_600_1500.jpg"
                alt="logo LOKA"
              />
            </Card>
          </Box>

          <Box
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <IconButton
              size="large"
              edge="start"
              color="inherit"
              aria-label="menu"
              sx={{ mr: 2 }}
              onClick={() => logOut()}
            >
              <Tooltip title="Se déconnecter">
                <LogoutIcon />
              </Tooltip>
            </IconButton>
            <IconButton
              size="large"
              edge="start"
              color="inherit"
              aria-label="menu"
              sx={{ mr: 2 }}
              onClick={() => {
                setIsUserDetailOpen(true);
              }}
            >
              <Tooltip title="Paramètres">
                <SettingsIcon />
              </Tooltip>
            </IconButton>
            <Typography
              variant="h6"
              component="div"
              noWrap
              sx={{ marginRight: 5 }}
            >
              {`${user?.lastName} ${user?.firstName}`}
            </Typography>
          </Box>
        </Toolbar>
        <DialogBody
          Transition={Transition}
          categories={categories}
          requiredFields={requiredFields}
          isUserDetailOpen={isUserDetailOpen}
          setIsUserDetailOpen={setIsUserDetailOpen}
          bookingRestrictions={bookingRestrictions}
        />
        <LokaSnackBar />
        <LokaActivityIndicator />
      </AppBar>
    </Box>
  );
}
