import ArticleIcon from "@mui/icons-material/Article";
import DateRangeIcon from "@mui/icons-material/DateRange";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import { BottomNavigation, BottomNavigationAction } from "@mui/material";
import Box from "@mui/material/Box";
import * as React from "react";
import Colors from "../constants/colors.json";
import Language from "../constants/language_fr.json";

export default function AppBottomNavigation(props) {
  const { screenIndex, setScreenIndex } = props;

  const tabs = [
    { label: "calendarLabel", icon: <DateRangeIcon /> },
    { label: "catalogLabel", icon: <FormatListBulletedIcon /> },
    { label: "bookingLabel", icon: <ArticleIcon /> },
  ];

  return (
    <Box sx={{ position: "fixed", right: 0, left: 0, bottom: 0, zIndex: 10 }}>
      <BottomNavigation
        showLabels
        value={screenIndex}
        onChange={(event, newValue) => {
          setScreenIndex(newValue);
        }}
      >
        {tabs.map((tab, index) => (
          <BottomNavigationAction
            key={tab.label}
            label={Language.bottomNavigation[tab.label]}
            icon={tab.icon}
            sx={
              screenIndex === index
                ? { backgroundColor: Colors.greenShaded, cursor: "pointer" }
                : { cursor: "pointer" }
            }
          />
        ))}
      </BottomNavigation>
    </Box>
  );
}
