import React, { useContext, useState } from "react";
import {
  AppBar,
  Box,
  Toolbar,
  Typography,
  Dialog,
  Tabs,
  Tab,
  ThemeProvider,
} from "@mui/material";
import { createTheme } from "@mui/material/styles";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Color from "../constants/colors.json";
import Language from "../constants/language_fr.json";
import Profile from "./Profile";
import TeamManagement from "./TeamManagement";
import ConcessionParams from "./concessionParams";
import CompanyManagement from "./companyManagement";
import ActivityIndicatorContext from "../Contexts/ActivityIndicatorContext";
import SettingsSuggestIcon from "@mui/icons-material/SettingsSuggest";
import SnackBarContext from "../Contexts/SnackBarContext";
import { useDispatch, useSelector } from "react-redux";
import { setUser } from "../redux/actions";

export default function TabsBar({
  isUserDetailOpen,
  setIsUserDetailOpen,
  Transition,
  categories,
  requiredFields,
  bookingRestrictions,
}) {
  const dispatch = useDispatch();
  const [tabValue, setTabValue] = useState(0);
  const { company, user } = useSelector(({ slice }) => slice?.value);
  const { id, idCompany, licence } = user || {};
  const [expanded, setExpanded] = useState("dealerUsers");
  const [modifiableUserInformations, setModifiableUserInformations] = useState({
    id,
    idCompany,
  });
  const { setActivityIndicator } = useContext(ActivityIndicatorContext);
  const { setSnackBar } = useContext(SnackBarContext);
  const handleCloseDialog = () => {
    setTabValue(0);
    setIsUserDetailOpen(false);
  };
  const handleChangeTabValue = (event, newValue) => {
    setTabValue(newValue);
  };
  const muiTheme = createTheme({
    components: {
      MuiTabs: {
        styleOverrides: {
          indicator: {
            backgroundColor: Color.green,
          },
        },
      },
      MuiTab: {
        styleOverrides: {
          root: {
            borderRadius: "3px",
            "&:hover": {
              color: Color.green,
            },
            "&.Mui-selected": {
              color: Color.green,

              "&:hover": {
                color: Color.green,
              },
            },
          },
        },
      },
    },
  });
  const { tabProfile, tabDealer, dealerParameters, tabSupervision } =
    Language.appBar || {};
  const tabsList = [
    { key: "tabProfile", label: tabProfile, isDisabled: false },
    { key: "tabDealer", label: tabDealer, isDisabled: false },
    { key: "dealerParameters", label: dealerParameters, isDisabled: false },
    {
      key: "tabSupervision",
      label: tabSupervision,
      isDisabled: licence?.toLowerCase() !== "admin",
    },
  ];
  return (
    <Dialog
      fullScreen
      onClose={() => handleCloseDialog()}
      open={isUserDetailOpen}
      TransitionComponent={Transition}
    >
      <AppBar
        style={{
          position: "absolute",
          backgroundColor: Color.lightgrey,
          color: Color.white,
          top: "0px",
          left: "0px",
          height: "55px",
          right: "0px",
          overflow: "hidden",
        }}
      >
        <Toolbar>
          <Box
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <IconButton
              edge="start"
              color="inherit"
              onClick={() => handleCloseDialog()}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>

            <SettingsSuggestIcon
              fontSize="large"
              style={{ marginRight: "10px" }}
            />
            <Typography
              variant="h6"
              component="div"
              noWrap
              sx={{ marginRight: 5 }}
            >
              {Language.appBar.mainTitle}
            </Typography>
          </Box>
        </Toolbar>
      </AppBar>
      <div
        style={{
          flex: 1,
          marginTop: "80px",
          padding: "25px",
        }}
      >
        <div style={{ flex: 1 }}>
          <ThemeProvider theme={muiTheme}>
            <Tabs
              value={tabValue}
              onChange={handleChangeTabValue}
              aria-label="disabled tabs example"
              centered
              initialSelectedIndex={tabValue}
            >
              {tabsList.map(
                ({ key, label, isDisabled }) =>
                  !isDisabled && <Tab key={key} label={label} />
              )}
            </Tabs>
          </ThemeProvider>
        </div>
        {tabValue === 0 && (
          <Profile
            modifiableUserInformations={modifiableUserInformations}
            setModifiableUserInformations={setModifiableUserInformations}
            setActivityIndicator={setActivityIndicator}
            setSnackBar={(props) => setSnackBar(props)}
            setUser={(props) => dispatch(setUser(props))}
            userInfo={user}
          />
        )}
        {tabValue === 1 && (
          <TeamManagement
            userInfo={user}
            companyInfo={company}
            bookingRestrictions={bookingRestrictions}
            //setSnackBar={(props) => setSnackBar(props)}
          />
        )}

        {tabValue === 2 && (
          <ConcessionParams
            companyInfo={company}
            userInfo={user}
            expanded={expanded}
            setExpanded={setExpanded}
            setSnackBar={(props) => setSnackBar(props)}
            setActivityIndicator={setActivityIndicator}
            categories={categories}
            requiredFields={requiredFields}
          />
        )}
        {tabValue === 3 && (
          <CompanyManagement
            setSnackBar={(props) => setSnackBar(props)}
            companyInfo={company}
            userInfo={user}
            setActivityIndicator={setActivityIndicator}
          />
        )}
      </div>
    </Dialog>
  );
}
