import SaveIcon from "@mui/icons-material/Save";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Typography,
} from "@mui/material";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import React, { useContext, useState } from "react";
//api
import Company from "../../api/company";
import Color from "../../constants/colors.json";
import Language from "../../constants/language_fr.json";
//constants
import Styles from "../../constants/styles.json";
import { setCompany } from "../../redux/actions";
import { useDispatch, useSelector } from "react-redux";
import SnackBarContext from "../../Contexts/SnackBarContext";

export default function CompanyNotifications({ expanded, setExpanded }) {
  const dispatch = useDispatch();
  const { setSnackBar } = useContext(SnackBarContext);
  const { company, user } = useSelector(({ slice }) => slice.value);
  const [companyForm, setCompanyForm] = useState(company);

  const handleSwitchEmailNotifications = ({ key }) => {
    const { emailNotifications } = companyForm || {};
    const _emailNotifications = {
      ...emailNotifications,
      [key]: !emailNotifications?.[key],
    };
    setCompanyForm({ ...companyForm, emailNotifications: _emailNotifications });
  };

  const notificationsCatalog = [
    { key: "onLateChecking", label: "Etat des lieux en retard" },
    { key: "onLateMaintenance", label: "Maintenance en retard" },
  ];
  const handleSave = async () => {
    const { Update } = Company;
    const { status } = await Update({
      company: companyForm,
      token: user.token,
    });

    if (status === 200) {
      setCompany(companyForm);
      localStorage.setItem("company", JSON.stringify(companyForm));
      dispatch(setCompany(companyForm));
      setSnackBar({
        isOpen: true,
        message: Language.snackBar.updateCompanySuccess,
        status: "success",
      });
    } else {
      setSnackBar({
        isOpen: true,
        message: Language.snackBar.updateCompanyError,
        status: "error",
      });
    }
  };

  return (
    <Accordion
      square
      style={{ width: "100%" }}
      expanded={expanded === "emailNotifications"}
      onChange={() =>
        expanded === "emailNotifications"
          ? setExpanded(null)
          : setExpanded("emailNotifications")
      }
    >
      <AccordionSummary>
        <NotificationsActiveIcon style={{ color: Color.green }} />
        <Typography variant="subtitle1" style={{ marginLeft: "5px" }}>
          {Language.appBar.emailNotifications}
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <FormGroup style={{ flex: 1 }}>
          {notificationsCatalog.map(({ key, label }) => (
            <FormControlLabel
              control={
                <Switch
                  color="secondary"
                  checked={companyForm?.emailNotifications?.[key] === true}
                  onChange={() =>
                    handleSwitchEmailNotifications({
                      key,
                    })
                  }
                />
              }
              label={label}
            />
          ))}
          <Button
            style={Styles.button}
            disabled={company === companyForm}
            variant="contained"
            color="secondary"
            onClick={() => handleSave()}
            type="submit"
            startIcon={<SaveIcon />}
          >
            {Language.appBar.add}
          </Button>
        </FormGroup>
      </AccordionDetails>
    </Accordion>
  );
}
