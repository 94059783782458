import React from "react";
import { format } from "date-fns";

import { Typography } from "@mui/material";

import DatePicker from "react-datepicker";
import fr from "date-fns/locale/fr";

import "react-datepicker/dist/react-datepicker.css";

import Colors from "../constants/colors.json";

const LokaSingleDatePicker = ({ excludeDates, value, setValue }) => {
  const _excludeDates = excludeDates
    ? excludeDates.map((date) => new Date(date))
    : [];

  const _selected = value ? new Date(value) : null;

  const handleDateChange = (date) => {
    const _date = format(new Date(date), "yyyy-MM-dd");
    setValue([_date]);
  };

  const renderDayContents = (day, date) => {
    const _isSelected = format(new Date(date), "yyyy-MM-dd") === value;
    const _isExluded = excludeDates
      ? excludeDates.includes(format(new Date(date), "yyyy-MM-dd"))
      : false;
    return _isSelected ? (
      <div
        style={{
          color: Colors.white,
          backgroundColor: Colors.green,
          fontWeight: "bold",
          borderRadius: 5,
        }}
      >
        {day}
      </div>
    ) : _isExluded ? (
      <div
        style={{
          color: Colors.white,
          backgroundColor: Colors.warning,
          fontWeight: "bold",
          borderRadius: 5,
        }}
      >
        {day}
      </div>
    ) : (
      <div style={{ backgroundColor: Colors.white, color: Colors.grey }}>
        {day}
      </div>
    );
  };

  return (
    <div>
      <DatePicker
        dateFormat="dd/MM/yyyy"
        showIcon
        locale={fr}
        onChange={handleDateChange}
        excludeDates={_excludeDates}
        selected={_selected}
        monthsShown={1}
        openToDate={new Date()}
        renderDayContents={renderDayContents}
        renderCustomHeader={({ decreaseMonth, increaseMonth, monthDate }) => (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              padding: 10,
            }}
          >
            <Typography
              sx={{
                fontWeight: "bold",
                cursor: "pointer",
                color: Colors.green,
                fontSize: 24,
              }}
              onClick={decreaseMonth}
            >{`<`}</Typography>
            <Typography variant="h6" color={Colors.grey}>
              {monthDate.toLocaleDateString("fr-FR", {
                month: "long",
                year: "numeric",
              })}
            </Typography>
            <Typography
              sx={{
                fontWeight: "bold",
                cursor: "pointer",
                color: Colors.green,
                fontSize: 24,
              }}
              onClick={increaseMonth}
            >{`>`}</Typography>
          </div>
        )}
        //inline
      />
    </div>
  );
};

export default LokaSingleDatePicker;
