import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import CloseIcon from "@mui/icons-material/Close";
import PersonIcon from "@mui/icons-material/Person";
import {
  AppBar,
  Box,
  Dialog,
  Divider,
  IconButton,
  Tab,
  Tabs,
  ThemeProvider,
  Toolbar,
  Typography,
} from "@mui/material";
import { createTheme } from "@mui/material/styles";
import "ag-grid-enterprise";
import { format } from "date-fns";
import React, { useState } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import Machine from "../api/machine";
import Colors from "../constants/colors.json";
import Language from "../constants/language_fr.json";
import "../App.css";

const CheckingHistory = ({
  open,
  handleClose,
  contractsByMachine,
  selectedMachine,
  user,
  members,
}) => {
  const [contractDetails, setContractDetails] = useState(null);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [localProps, setLocalProps] = useState({ loading: true });

  const constructor = async () => {
    const { token } = user || {};
    const { category } = selectedMachine?.machine || {};
    const { checkings: _checkingsConfig } = await Machine.Config({
      category,
      token,
    });
    const data = contractsByMachine?.length ? contractsByMachine[0] : null;
    const _createdByStartChecking = data?.contractsToStartCheckings
      ? members?.find(
          ({ id }) => id === data?.contractsToStartCheckings?.createdBy
        )
      : null;

    const _createdByStopChecking = data?.contractsToStopCheckings
      ? members?.find(
          ({ id }) => id === data?.contractsToStopCheckings?.createdBy
        )
      : null;
    data &&
      setContractDetails({
        ...data,
        createdByStartChecking: `${_createdByStartChecking?.firstName} ${_createdByStartChecking?.lastName}`,
        createdByStopChecking: `${_createdByStopChecking?.firstName} ${_createdByStopChecking?.lastName}`,
      });

    setLocalProps({
      checkingsConfig: _checkingsConfig,
      loading: false,
    });
  };

  const { checkingsConfig, loading } = localProps || {};

  const onRowClicked = async ({ data }) => {
    setCurrentIndex(0);
    const _createdByStartChecking = data?.contractsToStartCheckings
      ? members?.find(
          ({ id }) => id === data?.contractsToStartCheckings?.createdBy
        )
      : null;

    const _createdByStopChecking = data?.contractsToStopCheckings
      ? members?.find(
          ({ id }) => id === data?.contractsToStopCheckings?.createdBy
        )
      : null;
    setContractDetails({
      ...data,
      createdByStartChecking: `${_createdByStartChecking?.firstName} ${_createdByStartChecking?.lastName}`,
      createdByStopChecking: `${_createdByStopChecking?.firstName} ${_createdByStopChecking?.lastName}`,
    });
  };
  const displayImage = ({ url }) => {
    return (
      <div
        key={url}
        style={{
          flex: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {url ? (
          <LazyLoadImage
            key="displayedImage"
            src={url}
            style={{
              objectFit: "contain",
            }}
            alt=""
            width="50%"
            height="50%"
          />
        ) : (
          <Typography textAlign={"center"} color={Colors.greyShaded}>
            {Language?.catalog?.noPicture}
          </Typography>
        )}
      </div>
    );
  };
  const {
    contractsToStartCheckings,
    contractsToStopCheckings,
    createdByStartChecking,
    createdByStopChecking,
  } = contractDetails || {};

  const goToSelectedKey = (value) => {
    setCurrentIndex(value);
  };
  const muiTheme = createTheme({
    components: {
      MuiTabs: {
        styleOverrides: {
          indicator: {
            backgroundColor: "white",
          },
        },
      },
      MuiTab: {
        styleOverrides: {
          root: {
            borderRadius: "3px",
            minHeight: "15px",
            "&:hover": {
              backgroundColor: "#E6F6E0",
            },
            "&.Mui-selected": {
              color: Colors.white,
              backgroundColor: Colors.green,
              border: `0.5px solid ${Colors.green}`,

              "&:hover": {
                //color: Colors.green,
                color: Colors.white,
              },
            },
          },
        },
      },
    },
  });

  const CheckingDetails = ({ startChecking, stopChecking, currentIndex }) => {
    const checkings = checkingsConfig?.items?.map(({ key, ...rest }) => ({
      key,
      startValue: startChecking?.[key]?.value,
      stopValue: stopChecking?.[key]?.value,
      ...rest,
    }));
    const selectedPicture = checkings
      ?.filter(({ type }) => type === "picture")
      .find((item, index) => index === currentIndex);

    const checkingExceptions = ["signature"];

    return (
      <>
        <div
          style={{
            flex: 1,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Box
            sx={{
              bgcolor: "background.paper",
              overflowX: "scroll",
            }}
          >
            <ThemeProvider theme={muiTheme}>
              <Tabs
                value={currentIndex}
                onChange={(e, value) => goToSelectedKey(value)}
                variant="scrollable"
                scrollButtons="auto"
              >
                {checkings
                  ?.filter(
                    ({ startValue, stopValue, type }) =>
                      type === "picture" && (startValue || stopValue)
                  )
                  ?.map(({ label }) => (
                    <Tab style={{ fontSize: "0.6em" }} label={label} />
                  ))}
              </Tabs>
            </ThemeProvider>
          </Box>
        </div>
        <div style={{ display: "flex", flex: 1, flexDirection: "row" }}>
          <div
            style={{
              display: "flex",
              flex: 1,
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {["startValue", "stopValue"]?.map((key) =>
              displayImage({
                url: selectedPicture?.[key],
              })
            )}
          </div>
        </div>
        <div
          style={{
            marginTop: "10px",
            overflowY: "scroll",
          }}
        >
          {checkings
            ?.filter(
              ({ key, type }) =>
                type !== "picture" && !checkingExceptions.includes(key)
            )
            .map(({ key, label, startValue, stopValue, unity }) =>
              key ? (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    padding: "0.5vw",
                  }}
                >
                  <div style={{ display: "flex", flex: 2 }}>
                    <Typography color={Colors.grey}>{`${label}`}</Typography>
                  </div>
                  {[startValue, stopValue].map((checkingValue) => (
                    <div style={{ display: "flex", flex: 5 }}>
                      <Typography
                        color={checkingValue ? Colors.grey : Colors.greyShaded}
                      >
                        {checkingValue && checkingValue !== undefined
                          ? `${checkingValue} ${unity ? unity : ""}`
                          : Language?.catalog?.noValue}
                      </Typography>
                    </div>
                  ))}
                </div>
              ) : null
            )}
        </div>
      </>
    );
  };
  const { category, brand, model } = selectedMachine?.machine || {};

  loading && constructor();

  return (
    <Dialog fullScreen open={open}>
      <Box
        // width="100%"
        // height="100%"
        justifyContent="center"
        alignItems="center"
      >
        <Box>
          <AppBar position="static">
            <Toolbar>
              <IconButton
                size="large"
                edge="start"
                color="inherit"
                aria-label="menu"
                sx={{ mr: 2 }}
                onClick={() => handleClose()}
              >
                <CloseIcon />
              </IconButton>
              <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                {`${Language?.catalog?.history} ${Language.categoryConverter[category]} ${brand} ${model}`}
              </Typography>
            </Toolbar>
          </AppBar>
        </Box>

        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            flex: 1,
            //width: "100%",
          }}
        >
          <div
            className="z-index:9"
            style={{
              //height: "100%",
              justifyContent: "center",
              alignItems: "center",
              flex: 1,
            }}
          >
            {contractsByMachine?.map((data, index) => {
              const [_createdAt] = new Date(data.createdAt)
                .toLocaleString()
                .split(" ");

              const isSelected = data?.id === contractDetails?.id;
              return (
                <div
                  style={{
                    backgroundColor: isSelected
                      ? Colors.greyHighShaded
                      : Colors.white,
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <div
                    onClick={async () => onRowClicked({ data })}
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      padding: "1vw",
                      justifyContent: "space-between",
                      alignItems: "center",
                      cursor: "pointer",
                    }}
                  >
                    <div>
                      <p>
                        <Typography>{data?.customer?.name}</Typography>
                      </p>
                    </div>
                    <div>
                      <p>
                        <Typography style={{ fontSize: 12 }}>
                          {_createdAt}
                        </Typography>
                      </p>
                    </div>
                  </div>
                  {index < contractsByMachine?.length - 1 && (
                    <Divider
                      orientation="horizontal"
                      variant="middle"
                      flexItem
                    />
                  )}
                </div>
              );
            })}
          </div>

          <div
            style={{
              flex: 2,
              borderLeft: "1px solid lightgrey",
            }}
          >
            {contractDetails ? (
              <>
                <div
                  style={{
                    flex: 1,
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-around",
                  }}
                >
                  {[
                    {
                      date: format(
                        new Date(contractsToStartCheckings?.createdAt),
                        "dd/MM/yyyy à HH:mm"
                      ),
                      label: createdByStartChecking,
                      title: Language?.catalog?.checkin,
                    },
                    {
                      date: format(
                        new Date(contractsToStopCheckings?.createdAt),
                        "dd/MM/yyyy à HH:mm"
                      ),
                      label: createdByStopChecking,
                      title: Language?.catalog?.checkout,
                    },
                  ].map(({ date, label, title }, index) => (
                    <>
                      <div style={{ width: "50%" }}>
                        <div>
                          <Typography
                            textAlign={"center"}
                            padding={"3px"}
                            color={Colors.greyShaded}
                          >
                            {title}
                          </Typography>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-around",
                            padding: "0.5vw",
                          }}
                        >
                          {[
                            {
                              icon: (
                                <PersonIcon style={{ color: Colors.grey }} />
                              ),
                              title: label,
                            },
                            {
                              icon: (
                                <CalendarMonthIcon
                                  style={{ color: Colors.grey }}
                                />
                              ),
                              title: date,
                            },
                          ]?.map(({ icon, title }) => (
                            <Box display="flex" alignItems={"center"}>
                              {icon}
                              <Typography
                                fontSize={12}
                                style={{ color: Colors.grey }}
                              >
                                {title}
                              </Typography>
                            </Box>
                          ))}
                        </div>
                      </div>
                      {index === 0 && (
                        <Divider
                          orientation="vertical"
                          variant="middle"
                          flexItem
                        />
                      )}
                    </>
                  ))}
                </div>
                <Divider orientation="horizontal" variant="middle" flexItem />
                {contractsToStartCheckings || contractsToStopCheckings ? (
                  <CheckingDetails
                    startChecking={contractsToStartCheckings || {}}
                    stopChecking={contractsToStopCheckings || {}}
                    currentIndex={currentIndex}
                  />
                ) : (
                  <Box
                    height={"100%"}
                    justifyContent="center"
                    alignItems="center"
                    display="flex"
                  >
                    <Typography
                      style={{ textAlign: "center" }}
                      color={Colors.greyShaded}
                    >
                      {Language?.contractDetails?.noChecking}
                      <Typography
                        textAlign={"center"}
                        color={Colors.greyShaded}
                      >
                        {Language?.catalog?.enterChecking}
                      </Typography>
                    </Typography>
                  </Box>
                )}
              </>
            ) : (
              <Box
                height={"100%"}
                justifyContent="center"
                alignItems="center"
                display="flex"
              >
                <Typography
                  style={{
                    textAlign: "center",

                    padding: "10px",
                  }}
                  color={Colors.greyShaded}
                >
                  {Language?.catalog?.selectContract}
                </Typography>
              </Box>
            )}
          </div>
        </div>
      </Box>
    </Dialog>
  );
};
export default CheckingHistory;
