import { orange } from "@mui/material/colors";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import React, { useState } from "react";
import Company from "./api/company";
import User from "./api/user";
import Colors from "./constants/colors.json";
import ActionModalContext from "./Contexts/ActionModalContext";
import ActivityIndicatorContext from "./Contexts/ActivityIndicatorContext";
import SnackBarContext from "./Contexts/SnackBarContext";
import Home from "./views/Home";
import Login from "./views/Login";
import { useDispatch, useSelector } from "react-redux";
import { setCompany, setUser } from "./redux/actions";
import {
  ClientSideRowModelModule,
  ColumnAutoSizeModule,
  CustomFilterModule,
  DateFilterModule,
  LocaleModule,
  ModuleRegistry,
  NumberEditorModule,
  NumberFilterModule,
  PaginationModule,
  RowApiModule,
  RowStyleModule,
  TextEditorModule,
  TextFilterModule,
  ValidationModule,
} from "ag-grid-community";
import {
  ExcelExportModule,
  GroupFilterModule,
  LicenseManager,
  MultiFilterModule,
  SetFilterModule,
} from "ag-grid-enterprise";

function App() {
  LicenseManager.setLicenseKey(`${process.env.REACT_APP_AG_GRID_LICENCE_KEY}`);
  ModuleRegistry.registerModules([
    ClientSideRowModelModule,
    ColumnAutoSizeModule,
    CustomFilterModule,
    DateFilterModule,
    ExcelExportModule,
    GroupFilterModule,
    LocaleModule,
    MultiFilterModule,
    NumberEditorModule,
    NumberFilterModule,
    PaginationModule,
    RowApiModule,
    RowStyleModule,
    SetFilterModule,
    TextEditorModule,
    TextFilterModule,
    ValidationModule,
  ]);
  const globalTheme = createTheme({
    status: {
      danger: orange[500],
    },
    palette: {
      primary: {
        main: Colors.grey,
      },
      secondary: {
        main: Colors.green,
      },
    },
  });

  const dispatch = useDispatch();

  const [actionModal, setActionModal] = useState({
    isOpen: false,
    message: null,
    status: null,
    title: null,
  });

  const [activityIndicator, setActivityIndicator] = useState({
    isOpen: false,
    message: null,
    status: null,
  });
  // const [company, setCompany] = useState();
  const [snackBar, setSnackBar] = useState({
    isOpen: false,
    message: null,
    status: null,
  });
  // const [user, setUser] = useState();
  const [localProps, setLocalProps] = useState({
    isLoading: true,
  });

  const constructor = async () => {
    const _user = localStorage.getItem("user");
    const { id: idUser, idCompany, token } = JSON.parse(_user) || {};

    const { GetById: GetCompanyById } = Company || {};
    const { GetById: GetUserById } = User || {};

    const { data: _company } = _user
      ? await Promise.resolve(
          GetCompanyById({
            idCompany,
            token,
          })
        )
      : {};
    const _userFromApi = _user ? await GetUserById({ idUser, token }) : null;
    _company && dispatch(setCompany(_company));
    _userFromApi
      ? dispatch(setUser({ ..._userFromApi, token }))
      : dispatch(setUser(JSON.parse(_user)));
    _userFromApi &&
      localStorage.setItem("user", JSON.stringify({ ..._userFromApi, token }));
    _company && localStorage.setItem("company", JSON.stringify(_company));
    setLocalProps({
      isLoading: false,
    });
  };
  localProps?.isLoading && constructor();

  const { company, user } = useSelector(({ slice }) => slice?.value);
  const isLogged = Object.entries({ ...user, ...company })?.length > 0;

  return (
    <ThemeProvider theme={globalTheme}>
      <ActionModalContext.Provider value={{ actionModal, setActionModal }}>
        <ActivityIndicatorContext.Provider
          value={{ activityIndicator, setActivityIndicator }}
        >
          <SnackBarContext.Provider value={{ snackBar, setSnackBar }}>
            {localProps?.isLoading ? <></> : isLogged ? <Home /> : <Login />}
          </SnackBarContext.Provider>
        </ActivityIndicatorContext.Provider>
      </ActionModalContext.Provider>
    </ThemeProvider>
  );
}

export default App;
