import React from "react";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import Color from "../constants/colors.json";
import {  Typography } from "@mui/material";
import { Box, IconButton } from "@mui/material";
import Language from "../constants/language_fr.json";

function SliderMachinePictures({ pictures }) {
  const [activeStep, setActiveStep] = React.useState(0);
  const maxSteps = pictures && pictures.length;

  const displayImage = (step, index) => {
    let imageToDisplay = new Image();
    imageToDisplay.src = step;
    return (
      <div
        key={step}
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <LazyLoadImage
          key="displayedImage"
          src={step}
          style={{
            objectFit: "contain",
          }}
          alt=""
          width="100%"
          height="100%"
        />
      </div>
    );
  };

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  return (
    <div id="imageSlider">
      {pictures && pictures.length > 0 && (
        <>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-around",
              backgroundColor: Color.carouselGrey,
            }}
          >
            <IconButton
              size="small"
              onClick={handleBack}
              disabled={activeStep === 0}
              style={{ color: Color.green, fontSize: "2em" }}
            >
              <KeyboardArrowLeftIcon />
            </IconButton>
            <p>
              {activeStep + 1} / {maxSteps}{" "}
            </p>
            <IconButton
              size="small"
              onClick={handleNext}
              disabled={activeStep === maxSteps - 1}
              style={{ color: Color.green, fontSize: "2em" }}
            >
              <KeyboardArrowRightIcon />
            </IconButton>
          </Box>
        </>
      )}
      {pictures && pictures.length > 0 ? (
        <Carousel>
          {pictures.map((step, index) => displayImage(step, index))}
        </Carousel>
      ) : (
        <div
          style={{
            height: 300,
            flex: 1,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Typography
            component="p"
            style={{
              textAlign: "center",
              color: Color.grey,
            }}
          >
            {Language.sliderMachinePictures.noPicture}
          </Typography>
        </div>
      )}
    </div>
  );
}

export default SliderMachinePictures;
