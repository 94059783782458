import Axios from "axios";
const serverUrl = `${process.env.REACT_APP_SERVER_URL}`;
const user = {
  Login: async (props) => {
    const { email, password } = props;
    const endPoint = "/login";
    const response = await Axios({
      method: "post",
      baseURL: `${serverUrl}${endPoint}`,
      data: {
        email,
        password,
      },
      headers: { "Access-Control-Allow-Origin": "*" },
      responseType: "application/json",
    });
    return response;
  },
  Create: async ({ user, token }) => {
    const endPoint = "/user/create";
    const { data, status } = await Axios({
      method: "post",
      baseURL: `${serverUrl}${endPoint}`,
      data: { ...user },
      headers: {
        Authorization: `Bearer ${token}`,
      },
      responseType: "application/json",
    });

    return { data, status } || {};
  },
  Update: async ({ user, token }) => {
    const endPoint = "/user/update";
    const { data, status } = await Axios({
      method: "post",
      baseURL: `${serverUrl}${endPoint}`,
      data: { ...user },
      headers: {
        Authorization: `Bearer ${token}`,
      },
      responseType: "application/json",
    });

    return { data, status } || {};
  },
  ResetPassword: async ({ user, token }) => {
    const endPoint = "/user/resetPassword";
    const { data, status } = await Axios({
      method: "post",
      baseURL: `${serverUrl}${endPoint}`,
      data: { ...user },
      headers: {
        Authorization: `Bearer ${token}`,
      },
      responseType: "application/json",
    });

    return { data, status } || {};
  },
  DeleteById: async ({ idUser, token }) => {
    const endPoint = "/user/deleteById";
    const { status } = await Axios({
      method: "get",
      baseURL: `${serverUrl}${endPoint}`,
      params: {
        idUser,
      },
      headers: { Authorization: `Bearer ${token}` },
      responseType: "application/json",
    });
    return status || {};
  },
  GetByCompany: async ({ token }) => {
    const endPoint = "/user/getByCompany";
    const { data, status } = await Axios({
      method: "get",
      baseURL: `${serverUrl}${endPoint}`,
      headers: { Authorization: `Bearer ${token}` },
      responseType: "application/json",
    });
    return { data: data?.response, status } || {};
  },
  GetById: async ({ idUser, token }) => {
    const endPoint = "/user/getById";
    const { data } = await Axios({
      method: "get",
      baseURL: `${serverUrl}${endPoint}`,
      params: {
        idUser,
      },
      headers: { Authorization: `Bearer ${token}` },
      responseType: "application/json",
    });

    return data?.response || {};
  },
  GetAllUsers: async ({ token }) => {
    const endPoint = "/user";
    const { data, status } = await Axios({
      method: "get",
      baseURL: `${serverUrl}${endPoint}`,
      headers: { Authorization: `Bearer ${token}` },
      responseType: "application/json",
    });
    return data?.response;
  },
  importFromXls: async ({ formData, token }) => {
    const baseURL = `${serverUrl}/user/xlsToUser`;
    const axiosParams = {
      method: "post",
      baseURL,
      data: formData,
      headers: { Authorization: `Bearer ${token}` },
      config: {
        Accept: "application/json",
        "Content-Type": "multipart/form-data",
      },
    };

    const { data } = await Axios(axiosParams);

    return data || {};
  },
};

export default user;
