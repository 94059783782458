import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  IconButton,
  MenuItem,
  Select,
  Switch,
  TextField,
  Toolbar,
  Typography,
} from "@mui/material";
import React, { useContext, useState } from "react";
import Machine from "../api/machine";
import Colors from "../constants/colors.json";
import Language from "../constants/language_fr.json";
import ActivityIndicatorContext from "../Contexts/ActivityIndicatorContext";
import LokaActivityIndicator from "./LokaActivityIndicator";
import MachineApi from "../api/machine";
import CategoryApi from "../api/category";
import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from "@mui/icons-material/Search";
import { searchByStocks } from "../api/api-tk";
import CircularProgress from "@mui/material/CircularProgress";
import AddPhotoAlternateIcon from "@mui/icons-material/AddPhotoAlternate";
import Compressor from "compressorjs";
import AWS from "aws-sdk";
import LokaSingleDatePicker from "./LokaSingleDatePicker";
import LokaChipInput from "./LokaChipInput";
import SaveIcon from "@mui/icons-material/Save";
import { useSelector } from "react-redux";

import "react-datepicker/dist/react-datepicker.css";

const AddUpdateMachine = ({
  handleClose,
  handleMergeMachine,
  machineProps,
  role,
  setSnackBar,
  internIdList,
}) => {
  const {
    REACT_APP_LOKA_S3_APP_BUCKET,
    REACT_APP_S3_APP_REGION,
    REACT_APP_KEYPREFIX,
    REACT_APP_AWS_ACCESS_KEY_ID,
    REACT_APP_AWS_SECRET_ACCESS_KEY_ID,
  } = process.env;

  const machineModel = {
    machine: {},
    machineFeatures: {},
    options: {},
    rentMode: {},
    benefits: {},
    equipments: {},
    pictures: {},
    delivery: {},
    availableRangeDates: {},
    group: [],
  };
  const initialMachine = machineProps || machineModel;

  const { setActivityIndicator } = useContext(ActivityIndicatorContext);
  const { company, user } = useSelector(({ slice }) => slice.value);

  const [isSearchingStock, setIsSearchingStock] = useState(false);
  const [machine, setMachine] = useState(initialMachine);
  const [localProps, setlocalProps] = useState({ loading: true });
  const [suggestedStock, setSuggestedStock] = useState([]);
  const [search, setSearch] = useState("");

  const constructor = async () => {
    const companyCategories = await CategoryApi.GetByCompany({
      defaultCategories: company?.defaultCategories,
      token: user?.token,
    });
    const machineConfiguration = initialMachine?.machine?.category
      ? await MachineApi.Config({
          category: initialMachine?.machine?.category,
          token: user?.token,
        })
      : null;
    setlocalProps({ companyCategories, machineConfiguration, loading: false });
  };

  const handleImport = async () => {
    const {
      agency,
      machine: machineFromStock,
      prices,
      stockImageList,
    } = suggestedStock || {};
    const { brand, internId, nature, model, serialNumber, year } =
      machineFromStock;

    const { value: mainPicture } = stockImageList?.find(({ main }) => main);

    const _machine = {
      machine: {
        agency: agency?.name,
        brand,
        category: nature?.key,
        internId,
        model,
        serialNumber,
        valuePrice: String(prices?.customerSalePrice),
        year,
      },
      machineFeatures: {},
      options: {},
      rentMode: {},
      benefits: {},
      equipments: {},
      pictures: { frontLeft: mainPicture },
      delivery: {},
      availableRangeDates: {},
      group: [],
    };
    const machineConfiguration = await MachineApi.Config({
      category: nature?.key,
      token: user?.token,
    });
    setlocalProps({ ...localProps, machineConfiguration });

    setMachine(_machine);
    setSuggestedStock([]);
  };

  const handleInputChange = ({ stepKey, inpKey, value }) => {
    const machineStepValue = machine?.[stepKey] || {};
    setMachine({
      ...machine,
      [stepKey]: { ...machineStepValue, [inpKey]: value },
    });
  };

  const handleUploadImage = async ({ inpKey, file }) => {
    AWS.config.update({
      accessKeyId: REACT_APP_AWS_ACCESS_KEY_ID,
      secretAccessKey: REACT_APP_AWS_SECRET_ACCESS_KEY_ID,
    });

    const lokaBucket = new AWS.S3({
      params: { Bucket: REACT_APP_LOKA_S3_APP_BUCKET },
      region: REACT_APP_S3_APP_REGION,
    });

    if (file) {
      const _name = `${user?.id}_${Date.now()}.jpg`;

      new Compressor(file, {
        maxHeight: 800,
        maxWidth: 800,
        async success(result) {
          const params = {
            ACL: "public-read",
            Body: result,
            Bucket: `${REACT_APP_LOKA_S3_APP_BUCKET}/${REACT_APP_KEYPREFIX}`,
            Key: _name,
            ContentType: "image",
          };
          lokaBucket.putObject(params).send((err) => {
            err
              ? console.log({ err })
              : setMachine({
                  ...machine,
                  pictures: {
                    ...machine?.pictures,
                    [inpKey]: `https://loka-prod.s3.eu-west-3.amazonaws.com/UPLOADS/${_name}`,
                  },
                });
          });
        },
        error(err) {
          console.log({ err });
        },
      });
    }
  };

  const handleSearchOnTK = async () => {
    const { apiConnexions } = company?.config || {};
    if (apiConnexions) {
      setIsSearchingStock(true);
      const [stock] = await searchByStocks({
        stockId: search,
        token: user?.token,
      });
      const hasCategoryOnLoka = company?.defaultCategories?.includes(
        stock?.machine?.nature?.key
      );
      const { internId } = stock?.machine || {};
      const isDuplicate = internIdList?.includes(internId);
      if (!stock || !internId || isDuplicate) {
        setSnackBar({
          isOpen: true,
          message: `La recherche du matériel ${search} a échoué. Vérifiez qu'il existe bien sur TIK TRAK, que son numéro de parc existe et n'est pas utilisé sur INSPEKT`,
          status: "error",
        });
        setIsSearchingStock(false);
      } else {
        setSuggestedStock({ ...stock, hasCategoryOnLoka });
        setIsSearchingStock(false);
      }
    } else {
      setSnackBar({
        isOpen: true,
        message: `La concession ${company?.name} n'est pas connectée aux stocks TIK TRAK.`,
        status: "error",
      });
    }
  };

  const handleSelectCategory = async ({ category }) => {
    /**add a confirmation modal here because user will loose its data */
    const machineConfiguration = await MachineApi.Config({
      category,
      token: user?.token,
    });
    setMachine({ machine: { category } });
    setlocalProps({ ...localProps, machineConfiguration });
    setSuggestedStock([]);
  };

  const handleSubmit = async () => {
    const isUpdate = role === "updateMachine";
    const errorController = [
      {
        key: "isPriceError",
        condition:
          !machine?.rentMode?.unityPrice && !machine?.rentMode?.dayPrice,
        label: `un tarif de location`,
      },
      {
        key: "isInternIdError",
        condition: !machine?.machine?.internId,
        label: `un numéro de parc`,
      },
      {
        key: "isDuplicate",
        condition: internIdList?.includes(machine?.machine?.internId),
        label: `un numéro de parc unique`,
      },
    ];
    const hasError = errorController?.filter(({ condition }) => condition);

    if (hasError?.length) {
      setSnackBar({
        isOpen: true,
        message: `Vous devez renseigner ${hasError
          .map(({ label }) => label)
          .toString(", ")} pour créer une machine`,
        status: "error",
      });
    } else {
      setActivityIndicator({
        isOpen: true,
        message: isUpdate
          ? "⏳ Mise à jour de la machine en cours..."
          : "⏳ Machine en cours de création...",
      });
      isUpdate
        ? await Machine.updateById({ body: machine, token: user.token })
        : await Machine.create({
            body: machine,
            token: user?.token,
          });
      setMachine(machineModel);
      setActivityIndicator({
        isOpen: false,
        message: null,
      });
      handleMergeMachine();
      handleClose();
    }
  };

  const handleSwitch = ({ inpKey, stepKey }) => {
    const isChecked = machine?.[stepKey]?.[inpKey];
    const _machineStep = isChecked
      ? { ...machine?.[stepKey], [inpKey]: false }
      : { ...machine?.[stepKey], [inpKey]: true };
    setMachine({ ...machine, [stepKey]: _machineStep });
  };

  // useEffect(() => constructor(), []);

  const { companyCategories, loading, machineConfiguration } = localProps || {};
  loading && constructor();

  const multiNumericSchema = [
    { key: "model", name: "Modèle", isDisabled: false, isNumeric: false },
    {
      key: "serialNumber",
      name: "N° série",
      isDisabled: false,
      isNumeric: false,
    },
    {
      key: "unityPrice",
      name: `Prix par ${machineConfiguration?.rentMode?.machineUnity}`,
      isDisabled: !machine?.rentMode?.unityPrice,
      isNumeric: true,
    },
    {
      key: "dayPrice",
      name: "Prix par jour",
      isDisabled: !machine?.rentMode?.dayPrice,
      isNumeric: true,
    },
  ];

  const steps = [
    {
      helpers: "1. Identification",
      key: "machine",
      type: "standard",
    },
    {
      helpers: "2. Caractéristiques",
      key: "machineFeatures",
      type: "standard",
    },
    {
      helpers: "3. Options",
      key: "options",
      type: "standard",
    },
    {
      helpers: "4. Tarifs",
      key: "rentMode",
      type: "standard",
    },
    {
      helpers: "5. Prestations",
      key: "benefits",
      type: "standard",
    },
    {
      helpers: "6. Accessoires",
      key: "equipments",
      type: "standard",
    },
    {
      helpers: "7. Photos",
      key: "pictures",
      type: "standard",
    },
    {
      helpers: "8. Transport",
      key: "delivery",
      type: "standard",
    },
    {
      helpers: "9. Disponibilité",
      key: "availableRangeDates",
      type: "standard",
    },
    {
      helpers: "10. Groupes d'appartenance",
      key: "groupList",
      type: "standard",
    },
  ];

  const hasCategory = machine?.machine?.category;
  const canBeSave = JSON.stringify(initialMachine) !== JSON.stringify(machine);

  return loading ? null : (
    <Dialog
      fullWidth={true}
      maxWidth="md"
      open={true}
      onClose={() => handleClose()}
    >
      <LokaActivityIndicator />
      <Box sx={{ backgroundColor: Colors.grey }}>
        <Toolbar>
          <IconButton
            edge="start"
            onClick={() => handleClose()}
            aria-label="close"
          >
            <CloseIcon sx={{ color: Colors.white }} />
          </IconButton>
          <Typography variant="h6" color={Colors.white}>
            {role === "addMachine"
              ? Language.addMachine.createMachine
              : Language.addMachine.updateMachine}
          </Typography>
        </Toolbar>
      </Box>
      <DialogContent>
        <div>
          <div
            style={{
              flex: 1,
              display: "flex",
              gap: 10,
              alignItems: "center",
            }}
          >
            <div style={{ flex: 1 }}>
              <Select
                disabled={hasCategory}
                size="small"
                value={
                  machine.machine.category || Language.addMachine.selectCategory
                }
                onChange={({ target }) =>
                  handleSelectCategory({ category: target?.value })
                }
                sx={{ width: "100%" }}
              >
                <MenuItem
                  disabled
                  key="placeholder"
                  value={Language.addMachine.selectCategory}
                >
                  {Language.addMachine.selectCategory}
                </MenuItem>
                {companyCategories?.map(({ name, key: categoryKey }) => (
                  <MenuItem key={categoryKey} value={categoryKey}>
                    {name}
                  </MenuItem>
                ))}
              </Select>
            </div>
            {!hasCategory && (
              <>
                <div>
                  <Typography>ou</Typography>
                </div>
                <div
                  style={{
                    flex: 1,
                    display: "flex",
                    justifyContent: "flex-end",
                    gap: 10,
                  }}
                >
                  <TextField
                    inputProps={{ type: "number" }}
                    size="small"
                    id="search-on-tk-textfield"
                    label="saisir un ID de STOCK"
                    onChange={({ target }) => setSearch(target?.value)}
                    placeholder="Import TIK TRAK"
                    slotProps={{
                      input: {
                        startAdornment: (
                          <InputAdornment position="start">
                            <SearchIcon />
                          </InputAdornment>
                        ),
                      },
                    }}
                    variant="outlined"
                    style={{ flex: 2 }}
                  />
                  <Button
                    disabled={!search}
                    size="small"
                    onClick={handleSearchOnTK}
                    variant="contained"
                    color="success"
                    style={{ flex: 1 }}
                  >
                    {isSearchingStock ? (
                      <CircularProgress style={{ color: "white" }} />
                    ) : (
                      `Rechercher`
                    )}
                  </Button>
                </div>
              </>
            )}
          </div>
          {suggestedStock?.machine?.nature?.key ? (
            <div
              style={{
                padding: 20,
                margin: 10,
                border: 1,
                borderRadius: 5,
                borderColor: Colors.greyShaded,
                borderStyle: "solid",
                display: "flex",
                flexDirection: "row",
                flex: 5,
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              {
                <>
                  <div style={{ flex: 5 }}>
                    <Typography
                      style={{ color: Colors.greyShaded }}
                    >{`Id : ${suggestedStock?.id}`}</Typography>
                    <Typography
                      style={{ color: Colors.grey }}
                    >{`${suggestedStock?.machine?.nature?.name} ${suggestedStock?.machine?.brand} ${suggestedStock?.machine?.model}`}</Typography>
                    <Typography
                      style={{ color: Colors.grey }}
                    >{`Année ${suggestedStock?.machine?.year} ${suggestedStock?.machine?.brand}`}</Typography>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-end",
                      justifyContent: "center",
                      flex: 2,
                    }}
                  >
                    <Button
                      disabled={!suggestedStock?.hasCategoryOnLoka}
                      size="small"
                      onClick={handleImport}
                      variant="contained"
                      color="success"
                      style={{ flex: 1 }}
                    >
                      Importer
                    </Button>
                    {!suggestedStock?.hasCategoryOnLoka ? (
                      <Typography
                        sx={{
                          textAlign: "right",
                          color: Colors.warning,
                          fontSize: 12,
                          flexWrap: "wrap",
                        }}
                      >{`La catégorie ${suggestedStock?.machine?.nature?.name?.toUpperCase()} n'est pas gérée pour votre concession sur LOKA.`}</Typography>
                    ) : null}
                  </div>
                </>
              }
            </div>
          ) : null}

          <div style={{ display: "flex", flexDirection: "column", gap: 10 }}>
            {machineConfiguration
              ? steps.map(({ helpers, key: stepKey }) => {
                  const inputList = machineConfiguration?.[stepKey]?.items;

                  return (
                    <Box key={stepKey} flex={2} padding={5}>
                      <Box
                        display="flex"
                        flexDirection="row"
                        justifyContent="space-between"
                        sx={{
                          borderBottomWidth: 1,
                          borderBottomColor: Colors.greyShaded,
                          borderBottomStyle: "solid",
                        }}
                        paddingBottom={2}
                      >
                        <Box sx={{ flex: 1 }}>
                          <Typography variant="h6" color={Colors.green}>
                            {`${helpers}`}
                          </Typography>
                        </Box>
                      </Box>
                      <Box
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          flexWrap: "wrap",
                          justifyContent: "center",
                          gap: 10,
                          paddingTop: 10,
                        }}
                      >
                        {inputList?.map(
                          ({
                            data: inpData,
                            label: inpLabel,
                            placeholder: inpPlaceholder,
                            name: inpName,
                            type: inpType,
                            key: inpKey,
                          }) => {
                            return inpType === "default" ? (
                              <div key={inpKey} style={{ flex: 1 }}>
                                <TextField
                                  key={inpKey}
                                  id={inpKey}
                                  label={inpLabel}
                                  value={machine?.[stepKey]?.[inpKey]}
                                  onChange={({ target }) =>
                                    handleInputChange({
                                      inpKey,
                                      stepKey,
                                      value: target?.value,
                                    })
                                  }
                                  style={{ width: 300 }}
                                />
                              </div>
                            ) : ["deliveryInput", "numeric"].includes(
                                inpType
                              ) ? (
                              <div key={inpKey} style={{ flex: 1 }}>
                                <TextField
                                  inputProps={{ type: "number" }}
                                  key={inpKey}
                                  id={inpKey}
                                  label={inpLabel}
                                  value={machine?.[stepKey]?.[inpKey]}
                                  onChange={({ target }) =>
                                    handleInputChange({
                                      inpKey,
                                      stepKey,
                                      value: target?.value,
                                    })
                                  }
                                  style={{ width: 300 }}
                                />
                              </div>
                            ) : inpType === "picker" ? (
                              inpKey === "agency" ? (
                                <div key={inpKey} style={{ flex: 1 }}>
                                  <Select
                                    value={
                                      machine?.[stepKey]?.[inpKey] ||
                                      inpPlaceholder
                                    }
                                    onChange={({ target }) =>
                                      handleInputChange({
                                        inpKey,
                                        stepKey,
                                        value: target?.value,
                                      })
                                    }
                                    style={{ width: 300 }}
                                  >
                                    <MenuItem
                                      disabled
                                      key="placeholder"
                                      value={inpPlaceholder}
                                    >
                                      {inpPlaceholder}
                                    </MenuItem>
                                    {company?.agencies?.map(({ name }) => (
                                      <MenuItem key={name} value={name}>
                                        {name}
                                      </MenuItem>
                                    ))}
                                  </Select>
                                </div>
                              ) : (
                                <div key={inpKey} style={{ flex: 1 }}>
                                  <Select
                                    value={
                                      machine?.[stepKey]?.[inpKey] ||
                                      inpPlaceholder
                                    }
                                    onChange={({ target }) =>
                                      handleInputChange({
                                        inpKey,
                                        stepKey,
                                        value: target?.value,
                                      })
                                    }
                                    style={{ width: 300 }}
                                  >
                                    <MenuItem
                                      disabled
                                      key="placeholder"
                                      value={inpPlaceholder}
                                    >
                                      {inpPlaceholder}
                                    </MenuItem>
                                    {inpData?.map((item) => (
                                      <MenuItem key={item} value={item}>
                                        {item}
                                      </MenuItem>
                                    ))}
                                  </Select>
                                </div>
                              )
                            ) : inpType === "checkbox" ? (
                              <div
                                key={inpKey}
                                style={{
                                  width: "40%",
                                  display: "flex",
                                  flexDirection: "row",
                                  justifyContent: "flex-start",
                                }}
                              >
                                <Switch
                                  checked={machine?.[stepKey]?.[inpKey]}
                                  onChange={() =>
                                    handleSwitch({ inpKey, stepKey })
                                  }
                                  color="success"
                                />
                                <Typography size="small">{inpLabel}</Typography>
                              </div>
                            ) : inpType === "multi-numeric" ? (
                              <div>
                                <Typography>{`${inpLabel}`}</Typography>
                                <div
                                  key={inpKey}
                                  style={{
                                    width: "100%",
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: "flex-start",
                                    gap: 10,
                                  }}
                                >
                                  {multiNumericSchema?.map(
                                    ({ key, name, isDisabled, isNumeric }) => (
                                      <TextField
                                        disabled={isDisabled}
                                        inputProps={
                                          isNumeric ? { type: "number" } : {}
                                        }
                                        key={key}
                                        id={key}
                                        label={name}
                                        value={
                                          machine?.[stepKey]?.[inpKey]?.[key]
                                        }
                                        onChange={({ target }) =>
                                          handleInputChange({
                                            inpKey,
                                            stepKey,
                                            value: {
                                              ...machine?.[stepKey]?.[inpKey],
                                              [key]: target?.value,
                                            },
                                          })
                                        }
                                        style={{
                                          flex: 1,
                                          color: Colors.grayTheFirst,
                                        }}
                                      />
                                    )
                                  )}
                                </div>
                              </div>
                            ) : inpType === "picture" ? (
                              <div>
                                {machine?.pictures?.[inpKey] ? (
                                  <div
                                    style={{
                                      width: 200,
                                      height: 200,
                                      overflow: "hidden",
                                    }}
                                  >
                                    <img
                                      style={{
                                        borderRadius: 10,
                                      }}
                                      src={machine?.[stepKey]?.[inpKey]}
                                      alt={inpKey}
                                      width={200}
                                    />
                                  </div>
                                ) : (
                                  <div
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "center",
                                      width: 200,
                                      height: 200,
                                      border: 1,
                                      borderStyle: "solid",
                                      borderColor: Colors.greyHighShaded,
                                      borderRadius: 10,
                                    }}
                                  >
                                    <label
                                      htmlFor={`file_input${inpKey}`}
                                      style={{
                                        cursor: "pointer",
                                        flex: 1,
                                        display: "flex",
                                        flexDirection: "column",
                                        justifyContent: "center",
                                        alignItems: "center",
                                      }}
                                    >
                                      <AddPhotoAlternateIcon
                                        fontSize="large"
                                        sx={{ color: Colors.green, size: 30 }}
                                      />
                                      <div>
                                        {
                                          <Typography
                                            sx={{
                                              fontSize: 12,
                                              color: Colors.grey,
                                            }}
                                          >
                                            {inpLabel}
                                          </Typography>
                                        }
                                      </div>
                                    </label>
                                    <input
                                      id={`file_input${inpKey}`}
                                      type="file"
                                      onChange={(e) =>
                                        handleUploadImage({
                                          inpKey,
                                          file: e.target.files[0],
                                        })
                                      }
                                      accept=".jpeg, .jpg"
                                      style={{ display: "none" }}
                                    />
                                  </div>
                                )}
                              </div>
                            ) : inpType === "calendarInput" ? (
                              <div key={inpKey} style={{ flex: 1 }}>
                                <Typography
                                  style={{ color: Colors.grey, margin: 10 }}
                                >
                                  {inpLabel}
                                </Typography>
                                <LokaSingleDatePicker
                                  inpKey={inpKey}
                                  setValue={(date) =>
                                    setMachine({
                                      ...machine,
                                      [stepKey]: {
                                        ...machine?.[stepKey],
                                        [inpKey]: date,
                                      },
                                    })
                                  }
                                  value={machine?.[stepKey]?.[inpKey]?.[0]}
                                />
                              </div>
                            ) : inpType === "groupListChipInput" ? (
                              <LokaChipInput
                                key={inpKey}
                                chipList={company?.config?.groupList || []}
                                setValue={(_value) =>
                                  setMachine({ ...machine, group: _value })
                                }
                                value={machine?.group || []}
                              />
                            ) : null;
                          }
                        )}
                      </Box>
                    </Box>
                  );
                })
              : null}
            {hasCategory ? (
              <div
                style={{
                  flex: 1,
                  display: "flex",
                  justifyContent: "center",
                  gap: 5,
                }}
              >
                <IconButton
                  style={
                    !canBeSave
                      ? {
                          padding: 10,
                          backgroundColor: Colors.greyShaded,
                          borderRadius: 10,
                        }
                      : {
                          padding: 10,
                          backgroundColor: Colors.green,
                          borderRadius: 10,
                        }
                  }
                  disabled={!canBeSave}
                  edge="start"
                  onClick={handleSubmit}
                  aria-label="close"
                >
                  <SaveIcon sx={{ color: Colors.white }} />
                  <Typography sx={{ color: "white" }}>Valider</Typography>
                </IconButton>
              </div>
            ) : null}
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};
export default AddUpdateMachine;
