import { createSlice } from "@reduxjs/toolkit";

export const slice = createSlice({
  name: "state",
  initialState: { value: { company: {}, members: [], user: {} } },
  reducers: {
    setCompany: (state, action) => {
      state.value.company = { ...action.payload };
    },
    setMembers: (state, action) => {
      state.value.members = [...action.payload];
    },
    setUser: (state, action) => {
      state.value.user = { ...action.payload };
    },
  },
});

export const { setCompany, setMembers, setUser } = slice.actions;
export default slice.reducer;
