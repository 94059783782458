import DownloadIcon from "@mui/icons-material/Download";
import RefreshIcon from "@mui/icons-material/Refresh";
import { Box } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import "ag-grid-enterprise";
import { AgGridReact } from "ag-grid-react";
import React, { useContext, useEffect, useRef, useState } from "react";
import Booking from "../api/booking";
import "../App.css";
// !------
import { AG_GRID_LOCALE_FR } from "@ag-grid-community/locale";
import ContractDetails from "../components/ContractDetails";
import LokaSnackBar from "../components/LokaSnackBar";
import Colors from "../constants/colors.json";
import Language from "../constants/language_fr.json";
import SnackBarContext from "../Contexts/SnackBarContext";
import { Tooltip } from "@mui/material";
import LokaActivityIndicator from "../components/LokaActivityIndicator";
import ActivityIndicatorContext from "../Contexts/ActivityIndicatorContext";
import { useSelector } from "react-redux";

const BookingsView = () => {
  const { setActivityIndicator } = useContext(ActivityIndicatorContext);
  const { members, user } = useSelector(({ slice }) => slice.value);
  const { setSnackBar } = useContext(SnackBarContext);

  const { GetApiCompanyDiscountGrid, GetGridBookings, Update } = Booking || {};
  const [contractDetails, setContractDetails] = useState(null);
  const [localProps, setLocalProps] = useState({
    isGridReady: false,
  });
  const [view, setView] = useState("standard");

  const gridRef = useRef(null);

  const constructor = async () => {
    setActivityIndicator({
      isOpen: true,
      message: Language.appBar.loading,
    });
    const { data } = await GetGridBookings({
      token: user?.token,
    });
    const companyDiscountGrid = await GetApiCompanyDiscountGrid({
      idCompany: user.idCompany,
      token: user.token,
    });
    const bookingsList = data?.response || [];
    const defaultViews = [
      "standard",
      "planning",
      "counter",
      "bookingType",
      "accounting",
      "export",
    ];
    setLocalProps({
      companyDiscountGrid,
      defaultViews,
      rowData: bookingsList,
    });
    setActivityIndicator({
      isOpen: false,
      message: null,
    });
  };
  useEffect(() => {
    constructor();
  }, []);

  const getMember = (memberId) => {
    const _member = members?.find(({ id }) => id === memberId[0]);
    return _member ? `${_member?.firstName} ${_member?.lastName}` : ``;
  };

  const gridApiHandler = (apiType) => {
    const { api } = gridRef.current || {};
    if (api) {
      switch (apiType) {
        case "exportDataAsExcel":
          api.exportDataAsExcel();
          break;
        default:
          console.log("This grid api type is not used in LOKA app.");
      }
    }
  };

  const handleCloseAndRefresh = async ({ snackbar }) => {
    const { isOpen, message, status } = snackbar || {};
    constructor();
    setContractDetails(null);
    setSnackBar({
      isOpen: isOpen,
      message: message,
      status: status,
    });
  };
  const handleMergeBooking = ({ booking }) => {
    setContractDetails(booking);
    constructor();
  };

  const onFirstDataRendered = (params) => {
    /**Display data on full width */
    params.api.sizeColumnsToFit();
  };

  const defaultColDef = {
    resizable: true,
    rowSelection: "multiple",
    filter: true,
    suppressMenu: true,
  };

  const columnDefs = {
    standard: [
      {
        field: "contractType",
        headerName: "Type contrat",
        type: "textColumn",
        valueGetter: ({ data }) =>
          localProps?.companyDiscountGrid?.find(
            ({ key }) => key === data.contractType
          )?.label || "LOCATION",
      },
      {
        field: "internId",
        headerName: "N° parc",
        type: "textColumn",
        width: 120,
      },
      {
        comparator: (a, b) => {
          const [aDay, aMonth, aYear] = a?.split("/") || [];
          const [bDay, bMonth, bYear] = b?.split("/") || [];
          const aDate = a ? new Date(aYear, aMonth, aDay) : null;
          const bDate = b ? new Date(bYear, bMonth, bDay) : null;
          return aDate === bDate ? 0 : aDate > bDate ? 1 : -1;
        },
        field: "createdAt",
        headerName: "Date de création",
        filter: "agDateColumnFilter",
        filterParams: {
          buttons: ["clear", "apply"],
        },
        valueGetter: ({ data }) => {
          const [date] = new Date(data?.createdAt)
            .toLocaleString("fr-FR", {
              timeZone: "UTC",
            })
            .split(" ");
          return date;
        },
        width: 120,
      },
      {
        field: "createdBy",
        headerName: "Loker",
        type: "textColumn",
        valueGetter: ({ data }) => getMember([data.createdBy]),
        width: 120,
      },
      {
        field: "checker",
        headerName: "Checker",
        type: "textColumn",
        valueGetter: ({ data }) => getMember([data?.checker]),
        width: 120,
      },
      {
        field: "startDay",
        filter: "agDateColumnFilter",
        comparator: (a, b) => {
          const [aDay, aMonth, aYear] = a?.split("/") || [];
          const [bDay, bMonth, bYear] = b?.split("/") || [];
          const aDate = a ? new Date(aYear, aMonth, aDay) : null;
          const bDate = b ? new Date(bYear, bMonth, bDay) : null;
          return aDate === bDate ? 0 : aDate > bDate ? 1 : -1;
        },
        filterParams: {
          buttons: ["clear", "apply"],
          comparator: (filterLocalDateAtMidnight, cellValue) => {
            const dateAsString = cellValue;
            if (dateAsString == null) return -1;
            const dateParts = dateAsString.split("/");
            const cellDate = new Date(
              Number(dateParts[2]),
              Number(dateParts[1]) - 1,
              Number(dateParts[0])
            );
            if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
              return 0;
            }
            if (cellDate < filterLocalDateAtMidnight) {
              return -1;
            }
            if (cellDate > filterLocalDateAtMidnight) {
              return 1;
            }
            return 0;
          },
        },
        headerName: "Départ",
        valueGetter: ({ data }) => {
          const [date] = new Date(data?.startDay)
            .toLocaleString("fr-FR", {
              timeZone: "UTC",
            })
            .split(" ");
          return date;
        },
        width: 120,
      },
      {
        comparator: (a, b) => {
          const [aDay, aMonth, aYear] = a?.split("/") || [];
          const [bDay, bMonth, bYear] = b?.split("/") || [];
          const aDate = a ? new Date(aYear, aMonth, aDay) : null;
          const bDate = b ? new Date(bYear, bMonth, bDay) : null;
          return aDate === bDate ? 0 : aDate > bDate ? 1 : -1;
        },
        field: "stopDay",
        filter: "agDateColumnFilter",
        filterParams: {
          buttons: ["clear", "apply"],
        },
        headerName: "Retour",
        valueGetter: ({ data }) => {
          const [date] = new Date(data?.stopDay)
            .toLocaleString("fr-FR", {
              timeZone: "UTC",
            })
            .split(" ");
          return date;
        },
      },
      {
        field: "customer",
        headerName: "Client",
        type: "textColumn",
      },
      {
        headerName: "Nature",
        type: "textColumn",
        valueGetter: ({ data }) => Language.categoryConverter?.[data.category],
        width: 120,
      },
      {
        field: "brand",
        headerName: "Marque",
        type: "textColumn",
        width: 120,
      },
      {
        field: "model",
        headerName: "Modèle",
        type: "textColumn",
        width: 120,
      },
      {
        field: "departureAgency",
        headerName: "Agence départ",
        type: "textColumn",
        width: 120,
      },
      {
        field: "bookingStatus",
        headerName: "Statut",
        type: "textColumn",
        valueGetter: ({ data }) =>
          Language.bookingStatusRenderer?.[data.status],
        width: 120,
      },
      {
        field: "comment",
        headerName: "Commentaire",
        type: "textColumn",
        width: 350,
      },
    ],
    planning: [
      { field: "shortId", headerName: "Id", type: "textColumn", maxWidth: 60 },
      {
        field: "createdBy",
        headerName: "Loker",
        type: "textColumn",
        valueGetter: ({ data }) => getMember([data.createdBy]),
        width: 120,
      },
      { field: "customer", headerName: "Client", type: "textColumn" },
      {
        headerName: "Nature",
        type: "textColumn",
        valueGetter: ({ data }) => Language.categoryConverter?.[data.category],
      },
      { field: "brand", headerName: "Marque", type: "textColumn" },
      { field: "model", headerName: "Modèle", type: "textColumn" },
      {
        comparator: (a, b) => {
          const [aDay, aMonth, aYear] = a?.split("/") || [];
          const [bDay, bMonth, bYear] = b?.split("/") || [];
          const aDate = a ? new Date(aYear, aMonth, aDay) : null;
          const bDate = b ? new Date(bYear, bMonth, bDay) : null;
          return aDate === bDate ? 0 : aDate > bDate ? 1 : -1;
        },
        field: "createdAt",
        filter: "agDateColumnFilter",
        filterParams: {
          buttons: ["clear", "apply"],
        },
        headerName: "Date de création",
        valueGetter: ({ data }) => {
          const [date] = new Date(data?.createdAt)
            .toLocaleString("fr-FR", {
              timeZone: "UTC",
            })
            .split(" ");
          return date;
        },
      },
      {
        comparator: (a, b) => {
          const [aDay, aMonth, aYear] = a?.split("/") || [];
          const [bDay, bMonth, bYear] = b?.split("/") || [];
          const aDate = a ? new Date(aYear, aMonth, aDay) : null;
          const bDate = b ? new Date(bYear, bMonth, bDay) : null;
          return aDate === bDate ? 0 : aDate > bDate ? 1 : -1;
        },
        field: "startDay",
        filter: "agDateColumnFilter",
        filterParams: {
          buttons: ["clear", "apply"],
        },
        headerName: "Départ",
        valueGetter: ({ data }) => {
          const [date] = new Date(data?.startDay)
            .toLocaleString("fr-FR", {
              timeZone: "UTC",
            })
            .split(" ");
          return date;
        },
      },
      {
        comparator: (a, b) => {
          const [aDay, aMonth, aYear] = a?.split("/") || [];
          const [bDay, bMonth, bYear] = b?.split("/") || [];
          const aDate = a ? new Date(aYear, aMonth, aDay) : null;
          const bDate = b ? new Date(bYear, bMonth, bDay) : null;
          return aDate === bDate ? 0 : aDate > bDate ? 1 : -1;
        },
        field: "stopDay",
        filter: "agDateColumnFilter",
        filterParams: {
          buttons: ["clear", "apply"],
        },
        headerName: "Retour",
        valueGetter: ({ data }) => {
          const [date] = new Date(data?.stopDay)
            .toLocaleString("fr-FR", {
              timeZone: "UTC",
            })
            .split(" ");
          return date;
        },
      },
    ],
    counter: [
      {
        field: "internId",
        headerName: "N° parc",
        type: "textColumn",
        width: 120,
      },
      {
        comparator: (a, b) => {
          const [aDay, aMonth, aYear] = a?.split("/") || [];
          const [bDay, bMonth, bYear] = b?.split("/") || [];
          const aDate = a ? new Date(aYear, aMonth, aDay) : null;
          const bDate = b ? new Date(bYear, bMonth, bDay) : null;
          return aDate === bDate ? 0 : aDate > bDate ? 1 : -1;
        },
        field: "createdAt",
        filter: "agDateColumnFilter",
        filterParams: {
          buttons: ["clear", "apply"],
        },
        headerName: "Date de création",
        valueGetter: ({ data }) => {
          const [date] = new Date(data?.createdAt)
            .toLocaleString("fr-FR", {
              timeZone: "UTC",
            })
            .split(" ");
          return date;
        },
      },
      {
        comparator: (a, b) => {
          const [aDay, aMonth, aYear] = a?.split("/") || [];
          const [bDay, bMonth, bYear] = b?.split("/") || [];
          const aDate = a ? new Date(aYear, aMonth, aDay) : null;
          const bDate = b ? new Date(bYear, bMonth, bDay) : null;
          return aDate === bDate ? 0 : aDate > bDate ? 1 : -1;
        },
        field: "startDay",
        filter: "agDateColumnFilter",
        filterParams: {
          buttons: ["clear", "apply"],
        },
        headerName: "Départ",
        valueGetter: ({ data }) => {
          const [date] = new Date(data?.startDay)
            .toLocaleString("fr-FR", {
              timeZone: "UTC",
            })
            .split(" ");
          return date;
        },
      },
      { field: "customer", headerName: "Client", type: "textColumn" },
      {
        headerName: "Nature",
        type: "textColumn",
        valueGetter: ({ data }) => Language.categoryConverter?.[data.category],
      },
      { field: "brand", headerName: "Marque", type: "textColumn" },
      { field: "model", headerName: "Modèle", type: "textColumn" },
      {
        field: "contractType",
        headerName: "Type contrat",
        type: "textColumn",
        valueGetter: ({ data }) =>
          localProps?.companyDiscountGrid?.find(
            ({ key }) => key === data.contractType
          )?.label || "LOCATION",
      },
      {
        filter: "agNumberColumnFilter",
        headerName: "Compteur (heures/bottes)",
        valueGetter: ({ data: { realCounter } }) => {
          const _realCounter = Number(realCounter);
          return isNaN(_realCounter) ? null : _realCounter;
        },
      },
    ],
    bookingType: [
      { field: "shortId", headerName: "Id", type: "textColumn", maxWidth: 60 },
      {
        field: "internId",
        headerName: "N° parc",
        type: "textColumn",
        width: 120,
      },
      {
        comparator: (a, b) => {
          const [aDay, aMonth, aYear] = a?.split("/") || [];
          const [bDay, bMonth, bYear] = b?.split("/") || [];
          const aDate = a ? new Date(aYear, aMonth, aDay) : null;
          const bDate = b ? new Date(bYear, bMonth, bDay) : null;
          return aDate === bDate ? 0 : aDate > bDate ? 1 : -1;
        },
        field: "createdAt",
        filter: "agDateColumnFilter",
        filterParams: {
          buttons: ["clear", "apply"],
        },
        headerName: "Date de création",
        valueGetter: ({ data }) => {
          const [date] = new Date(data?.createdAt)
            .toLocaleString("fr-FR", {
              timeZone: "UTC",
            })
            .split(" ");
          return date;
        },
      },
      {
        comparator: (a, b) => {
          const [aDay, aMonth, aYear] = a?.split("/") || [];
          const [bDay, bMonth, bYear] = b?.split("/") || [];
          const aDate = a ? new Date(aYear, aMonth, aDay) : null;
          const bDate = b ? new Date(bYear, bMonth, bDay) : null;
          return aDate === bDate ? 0 : aDate > bDate ? 1 : -1;
        },
        field: "startDay",
        filter: "agDateColumnFilter",
        filterParams: {
          buttons: ["clear", "apply"],
        },
        headerName: "Départ",
        valueGetter: ({ data }) => {
          const [date] = new Date(data?.startDay)
            .toLocaleString("fr-FR", {
              timeZone: "UTC",
            })
            .split(" ");
          return date;
        },
      },
      {
        comparator: (a, b) => {
          const [aDay, aMonth, aYear] = a?.split("/") || [];
          const [bDay, bMonth, bYear] = b?.split("/") || [];
          const aDate = a ? new Date(aYear, aMonth, aDay) : null;
          const bDate = b ? new Date(bYear, bMonth, bDay) : null;
          return aDate === bDate ? 0 : aDate > bDate ? 1 : -1;
        },
        field: "stopDay",
        filter: "agDateColumnFilter",
        filterParams: {
          buttons: ["clear", "apply"],
        },
        headerName: "Retour",
        valueGetter: ({ data }) => {
          const [date] = new Date(data?.stopDay)
            .toLocaleString("fr-FR", {
              timeZone: "UTC",
            })
            .split(" ");
          return date;
        },
      },
      { field: "customer", headerName: "Client", type: "textColumn" },
      {
        headerName: "Nature",
        type: "textColumn",
        valueGetter: ({ data }) => Language.categoryConverter?.[data.category],
      },
      { field: "brand", headerName: "Marque", type: "textColumn" },
      { field: "model", headerName: "Modèle", type: "textColumn" },
      {
        field: "discount",
        headerName: "Remise",
        type: "textColumn",
      },
      {
        field: "contractType",
        headerName: "Type contrat",
        type: "textColumn",
        valueGetter: ({ data }) =>
          localProps?.companyDiscountGrid?.find(
            ({ key }) => key === data.contractType
          )?.label || "LOCATION",
      },
      {
        filter: "agNumberColumnFilter",
        headerName: "Compteur (heures/bottes)",
        valueGetter: ({ data: { realCounter } }) => {
          const _realCounter = Number(realCounter);
          return isNaN(_realCounter) ? null : _realCounter;
        },
      },
    ],
    accounting: [
      {
        field: "shortId",
        headerName: "Id",
        type: "textColumn",
        maxWidth: 60,
      },
      {
        field: "bookingStatus",
        headerName: "Statut",
        type: "textColumn",
        valueGetter: ({ data }) =>
          Language.bookingStatusRenderer?.[data.status],
        width: 120,
      },
      {
        editable: true,
        headerName: "N° facture",
        type: "textColumn",
        width: 120,
        valueGetter: ({ data }) => data.billId,
        valueSetter: ({ data, newValue, oldValue }) => {
          const isBookingBillable =
            newValue !== oldValue &&
            ["invoiced", "closed"].includes(data.status);
          if (isBookingBillable) {
            updateBillId({
              booking: data?.jsonObject,
              newBillId: newValue,
              oldBillId: oldValue,
            });

            data.billId = newValue;
            data.status = newValue?.length ? "invoiced" : "closed";
          } else {
            setSnackBar({
              isOpen: true,
              message: Language.addBooking.thisBookingIsNotBillable,
              status: "error",
            });
          }

          return isBookingBillable ? true : false;
        },
      },
      {
        comparator: (a, b) => {
          const [aDay, aMonth, aYear] = a?.split("/") || [];
          const [bDay, bMonth, bYear] = b?.split("/") || [];
          const aDate = a ? new Date(aYear, aMonth, aDay) : null;
          const bDate = b ? new Date(bYear, bMonth, bDay) : null;
          return aDate === bDate ? 0 : aDate > bDate ? 1 : -1;
        },
        field: "createdAt",
        filter: "agDateColumnFilter",
        filterParams: {
          buttons: ["clear", "apply"],
        },
        headerName: "Date de création",
        valueGetter: ({ data }) => {
          const [date] = new Date(data?.createdAt)
            .toLocaleString("fr-FR", {
              timeZone: "UTC",
            })
            .split(" ");
          return date;
        },
        width: 120,
      },
      {
        field: "createdBy",
        headerName: "Loker",
        type: "textColumn",
        valueGetter: ({ data }) => getMember([data.createdBy]),
        width: 120,
      },
      {
        comparator: (a, b) => {
          const [aDay, aMonth, aYear] = a?.split("/") || [];
          const [bDay, bMonth, bYear] = b?.split("/") || [];
          const aDate = a ? new Date(aYear, aMonth, aDay) : null;
          const bDate = b ? new Date(bYear, bMonth, bDay) : null;
          return aDate === bDate ? 0 : aDate > bDate ? 1 : -1;
        },
        field: "startDay",
        filter: "agDateColumnFilter",
        filterParams: {
          buttons: ["clear", "apply"],
        },
        headerName: "Départ",
        valueGetter: ({ data }) => {
          const [date] = new Date(data?.startDay)
            .toLocaleString("fr-FR", {
              timeZone: "UTC",
            })
            .split(" ");
          return date;
        },
        width: 120,
      },
      {
        comparator: (a, b) => {
          const [aDay, aMonth, aYear] = a?.split("/") || [];
          const [bDay, bMonth, bYear] = b?.split("/") || [];
          const aDate = a ? new Date(aYear, aMonth, aDay) : null;
          const bDate = b ? new Date(bYear, bMonth, bDay) : null;
          return aDate === bDate ? 0 : aDate > bDate ? 1 : -1;
        },
        field: "stopDay",
        filter: "agDateColumnFilter",
        filterParams: {
          buttons: ["clear", "apply"],
        },
        headerName: "Retour",
        valueGetter: ({ data }) => {
          const [date] = new Date(data?.stopDay)
            .toLocaleString("fr-FR", {
              timeZone: "UTC",
            })
            .split(" ");
          return date;
        },
      },
      {
        field: "customer",
        headerName: "Client",
        type: "textColumn",
      },
      {
        headerName: "Nature",
        type: "textColumn",
        valueGetter: ({ data }) => Language.categoryConverter?.[data.category],
        width: 120,
      },
      {
        field: "brand",
        headerName: "Marque",
        type: "textColumn",
        width: 120,
      },
      {
        field: "model",
        headerName: "Modèle",
        type: "textColumn",
        width: 120,
      },
      {
        field: "internId",
        headerName: "N° parc",
        type: "textColumn",
        width: 120,
      },
      {
        field: "comment",
        headerName: "Commentaire",
        type: "textColumn",
        width: 350,
      },
      {
        field: "contractType",
        headerName: "Type contrat",
        type: "textColumn",
        valueGetter: ({ data }) =>
          localProps?.companyDiscountGrid?.find(
            ({ key }) => key === data.contractType
          )?.label || "LOCATION",
      },
      {
        filter: "agNumberColumnFilter",
        headerName: "Compteur (heures/bottes)",
        valueGetter: ({ data: { realCounter } }) => {
          const _realCounter = Number(realCounter);
          return isNaN(_realCounter) ? null : _realCounter;
        },
      },
      {
        cellRenderer: ({ data }) => {
          const _netPrice = parseFloat(data.netPrice);
          return isNaN(_netPrice) ? null : `${_netPrice.toFixed(2)}€`;
        },
        headerName: "Total HT",
        type: "agNumberColumnFilter",
        valueGetter: ({ data }) => {
          const _netPrice = parseFloat(data.netPrice);
          return isNaN(_netPrice) ? null : _netPrice;
        },
      },
    ],
    export: [
      {
        field: "shortId",
        headerName: "Id",
        type: "textColumn",
        maxWidth: 60,
      },
      {
        field: "internId",
        headerName: "N° parc",
        type: "textColumn",
        width: 120,
      },
      {
        comparator: (a, b) => {
          const [aDay, aMonth, aYear] = a?.split("/") || [];
          const [bDay, bMonth, bYear] = b?.split("/") || [];
          const aDate = a ? new Date(aYear, aMonth, aDay) : null;
          const bDate = b ? new Date(bYear, bMonth, bDay) : null;
          return aDate === bDate ? 0 : aDate > bDate ? 1 : -1;
        },
        field: "createdAt",
        filter: "agDateColumnFilter",
        filterParams: {
          buttons: ["clear", "apply"],
        },
        headerName: "Date de création",
        valueGetter: ({ data }) => {
          const [date] = new Date(data?.createdAt)
            .toLocaleString("fr-FR", {
              timeZone: "UTC",
            })
            .split(" ");
          return date;
        },
        width: 120,
      },
      {
        field: "createdBy",
        headerName: "Loker",
        type: "textColumn",
        valueGetter: ({ data }) => getMember([data.createdBy]),
        width: 120,
      },
      {
        comparator: (a, b) => {
          const [aDay, aMonth, aYear] = a?.split("/") || [];
          const [bDay, bMonth, bYear] = b?.split("/") || [];
          const aDate = a ? new Date(aYear, aMonth, aDay) : null;
          const bDate = b ? new Date(bYear, bMonth, bDay) : null;
          return aDate === bDate ? 0 : aDate > bDate ? 1 : -1;
        },
        field: "startDay",
        filter: "agDateColumnFilter",
        filterParams: {
          buttons: ["clear", "apply"],
        },
        headerName: "Départ",
        valueGetter: ({ data }) => {
          const [date] = new Date(data?.startDay)
            .toLocaleString("fr-FR", {
              timeZone: "UTC",
            })
            .split(" ");
          return date;
        },
        width: 120,
      },
      {
        comparator: (a, b) => {
          const [aDay, aMonth, aYear] = a?.split("/") || [];
          const [bDay, bMonth, bYear] = b?.split("/") || [];
          const aDate = a ? new Date(aYear, aMonth, aDay) : null;
          const bDate = b ? new Date(bYear, bMonth, bDay) : null;
          return aDate === bDate ? 0 : aDate > bDate ? 1 : -1;
        },
        field: "stopDay",
        filter: "agDateColumnFilter",
        filterParams: {
          buttons: ["clear", "apply"],
        },
        headerName: "Retour",
        valueGetter: ({ data }) => {
          const [date] = new Date(data?.stopDay)
            .toLocaleString("fr-FR", {
              timeZone: "UTC",
            })
            .split(" ");
          return date;
        },
      },
      {
        field: "customer",
        headerName: "Client",
        type: "textColumn",
      },
      {
        headerName: "Nature",
        type: "textColumn",
        valueGetter: ({ data }) => Language.categoryConverter?.[data.category],
        width: 120,
      },
      {
        field: "brand",
        headerName: "Marque",
        type: "textColumn",
        width: 120,
      },
      {
        field: "model",
        headerName: "Modèle",
        type: "textColumn",
        width: 120,
      },
      {
        field: "departureAgency",
        headerName: "Agence départ",
        type: "textColumn",
        width: 120,
      },
      {
        field: "bookingStatus",
        headerName: "Statut",
        type: "textColumn",
        valueGetter: ({ data }) =>
          Language.bookingStatusRenderer?.[data.status],
        width: 120,
      },
      {
        field: "comment",
        headerName: "Commentaire",
        type: "textColumn",
        width: 350,
      },
      {
        field: "discount",
        headerName: "Remise",
        type: "textColumn",
      },
      {
        field: "contractType",
        headerName: "Type contrat",
        type: "textColumn",
        valueGetter: ({ data }) =>
          localProps?.companyDiscountGrid?.find(
            ({ key }) => key === data.contractType
          )?.label || "LOCATION",
      },
      {
        filter: "agNumberColumnFilter",
        headerName: "Compteur (heures/bottes)",
        valueGetter: ({ data: { realCounter } }) => {
          const _realCounter = Number(realCounter);
          return isNaN(_realCounter) ? null : _realCounter;
        },
      },
      {
        editable: true,
        headerName: "N° facture",
        type: "textColumn",
        width: 120,
        valueGetter: ({ data }) => data.billId,
        valueSetter: ({ data, newValue, oldValue }) => {
          const isBookingBillable =
            newValue !== oldValue &&
            ["invoiced", "closed"].includes(data.status);
          if (isBookingBillable) {
            updateBillId({
              booking: data?.jsonObject,
              newBillId: newValue,
              oldBillId: oldValue,
            });

            data.billId = newValue;
            data.status = newValue?.length ? "invoiced" : "closed";
          } else {
            setSnackBar({
              isOpen: true,
              message: Language.addBooking.thisBookingIsNotBillable,
              status: "error",
            });
          }

          return isBookingBillable ? true : false;
        },
      },
    ],
  };

  const rowClassRules = {
    "status-closed": function (params) {
      return params.data.status === "closed";
    },

    "status-invoiced": function (params) {
      return params.data.status === "invoiced";
    },
  };

  const updateBillId = async ({ booking, newBillId, oldBillId }) => {
    /**Updating billId is the only way to set a stage to "invoiced"
     * @mikl
     */
    const _stage = newBillId?.length ? "invoiced" : "closed";
    const _booking = { ...booking, billId: newBillId, stage: _stage };
    const { status } =
      newBillId !== oldBillId
        ? await Update({ booking: _booking, token: user?.token })
        : "no update to send";
    status === 200 &&
      setSnackBar({
        isOpen: true,
        message: Language.addBooking.updateBookingSuccess,
        status: "success",
      });
  };

  const { defaultViews, rowData = [] } = localProps || {};

  return (
    <div
      className="ag-theme-alpine"
      style={{ height: "100%", margin: "1%", paddingBottom: "5%" }}
    >
      <Box
        key="viewTitle"
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          marginTop: "2%",
          marginBottom: "2%",
          borderBottomColor: Colors.greyHighShaded,
          borderBottomWidth: 1,
          borderBottomStyle: "solid",
        }}
      >
        <Box
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
            <InputLabel id="view-select-label">Vues</InputLabel>
            <Select
              labelId="view-select-label"
              id="view-select"
              value={view}
              label="Vue"
              onChange={({ target }) => setView(target.value)}
            >
              {defaultViews?.map((view) => (
                <MenuItem key={view} value={view}>
                  {Language.viewLabels[view]}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
        <Box>
          <Tooltip title={Language.calendar.refreshData}>
            <RefreshIcon
              style={{ color: Colors.green, cursor: "pointer", marginLeft: 5 }}
              onClick={() => constructor()}
            />
          </Tooltip>
          <DownloadIcon
            style={{ color: Colors.green, cursor: "pointer", marginLeft: 5 }}
            onClick={() => gridApiHandler("exportDataAsExcel")}
          />
        </Box>
      </Box>
      {rowData?.length && (
        <div className="ag-theme-grid">
          <AgGridReact
            autoSizeStrategy={{ type: "fitGridWidth" }}
            localeText={AG_GRID_LOCALE_FR}
            rowData={rowData}
            rowClassRules={rowClassRules}
            domLayout="autoHeight"
            ref={gridRef}
            defaultColDef={defaultColDef}
            rowStyle={{
              borderBottomWidth: 1,
              borderColor: Colors.greyShaded,
              textAlign: "center",
              fontSize: "12px",
              cursor: "pointer",
            }}
            rowHeight={50}
            onCellClicked={({ data: { jsonObject }, colDef: { headerName } }) =>
              headerName !== "N° facture" && setContractDetails(jsonObject)
            }
            paginationPageSize={100}
            pagination={true}
            onFirstDataRendered={onFirstDataRendered}
            columnDefs={columnDefs?.[view]}
          />
        </div>
      )}
      {contractDetails !== null && (
        <ContractDetails
          contractDetails={contractDetails}
          discountGrid={localProps.companyDiscountGrid}
          handleMergeBooking={(props) => handleMergeBooking(props)}
          setContractDetails={setContractDetails}
          handleCloseAndRefresh={(props) => handleCloseAndRefresh(props)}
        />
      )}
      <LokaSnackBar />
      <LokaActivityIndicator />
    </div>
  );
};

export default BookingsView;
