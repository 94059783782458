import React, { useState } from "react";
import { addDays, differenceInDays, format } from "date-fns";

import { Typography } from "@mui/material";

import DatePicker from "react-datepicker";
import fr from "date-fns/locale/fr";

import "react-datepicker/dist/react-datepicker.css";

import Colors from "../constants/colors.json";

const LokaDatePicker = ({
  excludeDates,
  isEditable,
  rentalPeriod,
  setRentalPeriod,
}) => {
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const _excludeDates = excludeDates
    ? excludeDates.map((date) => new Date(date))
    : [];

  const handleDateChange = (dates) => {
    if (isEditable) {
      const [start, end] = dates;
      setStartDate(start);
      setEndDate(end);

      const duration = end ? differenceInDays(end, start) + 1 : 1;

      const _rentalPeriod = Array(duration)
        .fill(0)
        .map((moment, index) => {
          return format(addDays(new Date(start), index), "yyyy-MM-dd");
        });
      setRentalPeriod(_rentalPeriod);
    }
  };

  const renderDayContents = (day, date) => {
    const _isSelected = rentalPeriod.includes(
      format(new Date(date), "yyyy-MM-dd")
    );
    const _isExluded = excludeDates
      ? excludeDates.includes(format(new Date(date), "yyyy-MM-dd"))
      : false;
    return _isSelected ? (
      <div
        style={{
          color: Colors.white,
          backgroundColor: Colors.green,
          fontWeight: "bold",
          borderRadius: 5,
        }}
      >
        {day}
      </div>
    ) : _isExluded ? (
      <div
        style={{
          color: Colors.white,
          backgroundColor: Colors.warning,
          fontWeight: "bold",
          borderRadius: 5,
        }}
      >
        {day}
      </div>
    ) : (
      <div style={{ backgroundColor: Colors.white, color: Colors.grey }}>
        {day}
      </div>
    );
  };

  return (
    <div>
      <DatePicker
        //selected={new Date("2022-02-02")}
        locale={fr}
        onChange={handleDateChange}
        startDate={startDate}
        endDate={endDate}
        excludeDates={_excludeDates}
        selectsRange
        monthsShown={2}
        openToDate={
          rentalPeriod?.length ? new Date(rentalPeriod[0]) : new Date()
        }
        renderDayContents={renderDayContents}
        renderCustomHeader={({
          customHeaderCount,
          date,
          decreaseMonth,
          increaseMonth,
          monthDate,
        }) => (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              padding: 10,
            }}
          >
            <Typography
              sx={{
                fontWeight: "bold",
                cursor: "pointer",
                color: Colors.green,
                fontSize: 24,
                visibility: customHeaderCount === 1 ? "hidden" : true,
              }}
              onClick={decreaseMonth}
            >{`<`}</Typography>
            <Typography variant="h6" color={Colors.grey}>
              {monthDate.toLocaleDateString("fr-FR", {
                month: "long",
                year: "numeric",
              })}
            </Typography>
            <Typography
              sx={{
                fontWeight: "bold",
                cursor: "pointer",
                color: Colors.green,
                fontSize: 24,
                visibility: customHeaderCount === 0 ? "hidden" : true,
              }}
              onClick={increaseMonth}
            >{`>`}</Typography>
          </div>
        )}
        inline
      />
    </div>
  );
};

export default LokaDatePicker;
