import ListAltIcon from "@mui/icons-material/ListAlt";
import SaveIcon from "@mui/icons-material/Save";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Checkbox,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
//api
import Company from "../../api/company";
import Color from "../../constants/colors.json";
import Language from "../../constants/language_fr.json";
//constants
import Styles from "../../constants/styles.json";
import { setCompany, setUser } from "../../redux/actions";
import { useDispatch } from "react-redux";

export default function AddDefaultCategories({
  companyInfo,
  userInfo,
  expanded,
  setExpanded,
  setSnackBar,
  setActivityIndicator,
  categories,
}) {
  const dispatch = useDispatch();
  let { defaultCategories: categoriesList } = companyInfo || {};
  categoriesList = categories.filter((category) =>
    categoriesList.includes(category.key)
  );
  const [defaultCategories, setDefaultCategories] = useState(categoriesList);
  const [isDisabled, setIsDisabled] = useState(true);
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };
  const handleChange = (event) => {
    setIsDisabled(false);
    const {
      target: { value },
    } = event;
    setDefaultCategories(typeof value === "string" ? value.split(",") : value);
  };

  const submitDefaultCategories = async () => {
    setActivityIndicator({
      isOpen: true,
      message: Language.appBar.updateCompanyInProgress,
    });

    const { Update } = Company || {};
    const { token } = userInfo;
    const _categoryKeys = defaultCategories.map((category) => category.key);
    const _company = {
      ...companyInfo,
      defaultCategories: _categoryKeys,
    };
    try {
      const { status } = await Update({
        company: _company,
        token,
      });

      if (status === 200) {
        setIsDisabled(true);
        _company && dispatch(setCompany(_company));
        _company && localStorage.setItem("company", JSON.stringify(_company));
        setSnackBar({
          isOpen: true,
          message: Language.snackBar.updateCompanySuccess,
          status: "success",
        });
      } else {
        setSnackBar({
          isOpen: true,
          message: Language.snackBar.updateCompanyError,
          status: "error",
        });
      }
      setActivityIndicator({ isOpen: false, message: null });
    } catch (error) {
      setSnackBar({
        isOpen: true,
        message: Language.snackBar.updateCompanyError,
        status: "error",
      });
      setActivityIndicator({ isOpen: false, message: null });
    }
  };

  return (
    <Accordion
      square
      style={{ width: "100%" }}
      expanded={expanded === "defaultCategories"}
      onChange={() =>
        expanded === "defaultCategories"
          ? setExpanded(null)
          : setExpanded("defaultCategories")
      }
    >
      <AccordionSummary
        style={{
          display: "flex",
          alignItems: "center",
        }}
      >
        <ListAltIcon style={{ color: Color.green }} />
        <Typography variant="subtitle1" style={{ marginLeft: "5px" }}>
          {Language.appBar.addDefaultCategories}
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <FormControl
          key="DetailsAgency"
          style={{ width: "100%" }}
          sx={{ m: 1, width: 300 }}
        >
          <InputLabel>{Language.appBar.selectCatgory}</InputLabel>
          <Select
            value={defaultCategories}
            onChange={handleChange}
            input={<OutlinedInput label={Language.appBar.selectCatgory} />}
            MenuProps={MenuProps}
            multiple
            renderValue={(selected) => {
              const _selectedValues = selected.map((element) => element.name);
              return selected.length <= 3
                ? _selectedValues.join(", ")
                : `${selected.length} ${Language.appBar.multipleSelectedItems}`;
            }}
          >
            {categories
              .sort((a, b) => (b.name > a.name ? -1 : 1))
              .map((element) => (
                <MenuItem key={element.key} value={element}>
                  <Checkbox
                    checked={
                      defaultCategories
                        .map((category) => category.key)
                        .indexOf(element.key) > -1
                    }
                  />
                  <ListItemText primary={element.name} />
                </MenuItem>
              ))}
          </Select>

          <Button
            style={Styles.button}
            disabled={isDisabled}
            variant="contained"
            color="primary"
            onClick={() => submitDefaultCategories()}
            type="submit"
            startIcon={<SaveIcon />}
          >
            {Language.appBar.add}
          </Button>
        </FormControl>
      </AccordionDetails>
    </Accordion>
  );
}
