import LinkIcon from "@mui/icons-material/Link";
import CloseIcon from "@mui/icons-material/Close";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import ImageIcon from "@mui/icons-material/Image";
import UndoIcon from "@mui/icons-material/Undo";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import MailIcon from "@mui/icons-material/Mail";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import ActivityIndicatorContext from "../Contexts/ActivityIndicatorContext";
import { format } from "date-fns";
import AWS from "aws-sdk";
import {
  AppBar,
  Box,
  Button,
  CircularProgress,
  Dialog,
  Divider,
  IconButton,
  Link,
  Tab,
  Tabs,
  Toolbar,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useContext, useEffect, useState, useRef } from "react";
// import SwipeableViews from "react-swipeable-views";
import { Carousel } from "react-responsive-carousel";
import Booking from "../api/booking";
import ApiMachine from "../api/machine";
import Colors from "../constants/colors.json";
import Language from "../constants/language_fr.json";
import SnackBarContext from "../Contexts/SnackBarContext";
import AddUpdateBooking from "./AddUpdateBooking";
import LokaDatePicker from "./LokaDatePicker";
import LokaSnackBar from "./LokaSnackBar";
import ActionModalContext from "../Contexts/ActionModalContext";
import Axios from "axios";
import { getDiscountPrices } from "../globals";
import { DeleteCheckingById } from "../api/checking";
import { useSelector } from "react-redux";

const ContractDetails = (props) => {
  const { setActionModal } = useContext(ActionModalContext);
  const { setActivityIndicator } = useContext(ActivityIndicatorContext);
  const { setSnackBar } = useContext(SnackBarContext);
  const { members, user } = useSelector(({ slice }) => slice.value);
  const { licence, token } = user || {};
  const {
    deleteBooking: deleteBookingRestriction,
    updateBooking: updateBookingRestriction,
  } = user?.configuration?.restrictions || {};

  const {
    contractDetails,
    discountGrid,
    handleCloseAndRefresh,
    handleMergeBooking,
    setContractDetails,
  } = props || {};
  const { _id: _idBooking, id: idBooking } = contractDetails || {};

  const { DeleteById, SendMeBookingByMail } = Booking || {};

  const [isOpenAddUpBook, setIsOpenAddUpBook] = useState(false);
  const [localProps, setLocalProps] = useState({ loading: true });
  const [tabBarIndex, setTabBarIndex] = useState(0);
  const fileInputRef = useRef(null);

  const constructor = async () => {
    const { ConfigBooking, ConfigBookingKeyLabelConverter } = Booking;
    const {
      Config,
      ConfigKeyLabelConverter,
      ConfigKeyUnityConverter,
      getById,
    } = ApiMachine;

    const { category } = contractDetails?.machine?.machine || {};
    const _keyUnityConverter = await ConfigKeyUnityConverter({
      category,
      token,
    });
    const _bookingConfig = await ConfigBooking({
      category: category,
      token: token,
    });
    const _bookingConfigKeyLabelConverter =
      await ConfigBookingKeyLabelConverter({
        category: category,
        token: token,
      });
    const _machineConfigReq = await Config({
      category: category,
      token: token,
    });
    const _configKeyLabelConverter = await ConfigKeyLabelConverter({
      category: category,
      token: token,
    });
    const _keyLabelConverter = {
      ..._configKeyLabelConverter,
      ..._bookingConfigKeyLabelConverter,
    };
    const { id: idMachine } = contractDetails.machine;
    const _machine = await getById({ idMachine, token });

    setLocalProps({
      bookingConfiguration: _bookingConfig,
      keyLabelConverter: _keyLabelConverter,
      keyUnityConverter: _keyUnityConverter,
      loading: false,
      machineConfiguration: _machineConfigReq,
      machine: _machine,
    });
  };

  const {
    bookingConfiguration,
    keyLabelConverter,
    loading,
    machineConfiguration,
  } = localProps || {};
  useEffect(() => {
    constructor();
  }, []);

  const {
    attachments = [],
    bookingCosts,
    comment,
    contractsToStartCheckings,
    contractsToStopCheckings,
    createdAt,
    createdBy,
    customer,
    delivery,
    equipmentsSelected = {},
    id: bookingId,
    rentalPeriod,
    rentalPrices,
    servicesSelected = {},
    signature,
  } = contractDetails || {};
  const { customer: customerConfiguration } = bookingConfiguration || {};
  const {
    checkings: checkingsConfig,
    machine: machineConfig,
    rentMode: rentModeConfig,
  } = machineConfiguration || {};
  const { machine, pictures } = localProps?.machine || {};

  const appBarButtonList = [
    {
      key: "deleteBookingById",
      icon: <DeleteIcon />,
      onClick: () => handleDeleteBooking(),
      restriction: deleteBookingRestriction,
      tooltip: Language.contractDetails.deleteBooking,
    },
    {
      key: "updateBooking",
      icon: <ModeEditIcon />,
      onClick: () => setIsOpenAddUpBook(true),
      restriction: updateBookingRestriction,
      tooltip: Language.contractDetails.updateBooking,
    },
    {
      key: "sendMeBookingByMail",
      icon: <MailIcon />,
      onClick: () =>
        SendMeBookingByMail({ docType: "contract", idBooking, token }),
      tooltip: Language.contractDetails.sendMeBookingByMail,
    },
    {
      key: "pdfBookingById",
      icon: <PictureAsPdfIcon />,
      onClick: () => handleGetPdfBooking({ docType: "contract" }),
      restriction: false,
      tooltip: Language.contractDetails.pdfBooking,
    },
  ];

  const displayImage = ({ key, notes, url }) => {
    const isAssigned = localProps?.machine?.pictures?.[key] === url;
    return (
      <Box
        key={key}
        style={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        {["frontLeft", "frontRight", "rearLeft", "rearRight"].includes(key) ? (
          <Tooltip
            title={
              isAssigned
                ? Language.contractDetails.imageIsAssigned
                : Language.contractDetails.assignImage
            }
          >
            <Box
              style={{
                position: "absolute",
                bottom: 0,
                padding: 10,
                borderRadius: "50%",
                borderColor: Colors.white,
                borderWidth: 2,
                borderStyle: "solid",
                backgroundColor: isAssigned ? Colors.greyShaded : Colors.green,
                // opacity: 0.8,
                cursor: !isAssigned ? "pointer" : "standard",
                display: "flex",
              }}
            >
              <LinkIcon
                style={{ color: Colors.white }}
                onClick={() =>
                  !isAssigned ? handleUpdateMachinePicture({ key, url }) : null
                }
              />
            </Box>
          </Tooltip>
        ) : null}
        {notes ? (
          <Box>
            <Typography
              style={{
                borderBottomStyle: "solid",
                color: Colors.grayText,
                borderWidth: 1,
                borderColor: Colors.greyHighShaded,
                fontStyle: "italic",
                textAlign: "right",
              }}
            >
              {notes}
            </Typography>
          </Box>
        ) : null}
        {url ? (
          <Box>
            <img
              src={url}
              style={{ maxWidth: "100%", height: 500 }}
              alt={url}
            />
          </Box>
        ) : (
          <Box
            style={{
              height: 500,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <ImageIcon size={30} />
          </Box>
        )}
      </Box>
    );
  };

  const handleUpdateMachinePicture = async ({ key, url }) => {
    const { updateById } = ApiMachine;
    const { machine } = localProps;
    const pictures = { ...machine.pictures, [key]: url };
    const body = { ...machine, pictures };

    const { status } = await updateById({ body, token });

    if (status === 200) {
      setSnackBar({
        isOpen: true,
        message: "👏 L'image est désormais affectée.",
        status: "success",
      });
      constructor();
    } else {
      setSnackBar({
        isOpen: true,
        message: "😔 Quelquechose s'est mal passé lors de la mise à jour.",
        status: "error",
      });
    }
  };

  const EquipmentsItem = ({ data }) => {
    const _data = data ? Object.keys(data).filter((el) => data[el]) : [];

    return (
      <>
        {_data?.length ? (
          _data.map(
            (key) =>
              data[key] && (
                <Typography color={Colors.grey} key={key}>
                  {`${keyLabelConverter[key]} : ${rentalPrices.quantity} ${
                    Language.contractDetails[rentalPrices.key]
                  } x ${data[key][rentalPrices.key]}€`}
                </Typography>
              )
          )
        ) : (
          <Typography color={Colors.grey}>
            {Language.contractDetails.noEquipmentSelected}
          </Typography>
        )}
      </>
    );
  };

  const handleDeleteBooking = async () => {
    const deleteBooking = async () => {
      const status = await DeleteById({ idBooking, token });
      if (status === 200) {
        handleCloseAndRefresh({
          snackbar: {
            isOpen: true,
            message: "👏 Contrat supprimé.",
            status: "success",
          },
        });
      } else {
        setSnackBar({
          isOpen: true,
          message: "😲 Echec suppression.",
          status: "error",
        });
      }
    };
    setActionModal({
      handleValid: () => deleteBooking(),
      isOpen: true,
      message: Language.actionModal.bookingDefinitlyDeleted,
      status: null,
      title: Language.actionModal.caution,
    });
  };

  const handleDeleteChecking = async ({ checkingKey }) => {
    const deleteChecking = async () => {
      const { data, status } = await DeleteCheckingById({
        bookingId,
        checkingKey,
        token,
      });
      if (status === 200) {
        handleMergeBooking({ booking: data });
        setSnackBar({
          isOpen: true,
          message: "👏 Etat des lieux supprimé.",
          status: "success",
        });
      } else {
        setSnackBar({
          isOpen: true,
          message: "😲 La suppression de l'état des lieux a échoué.",
          status: "error",
        });
      }
    };

    setActionModal({
      handleValid: () => deleteChecking(),
      isOpen: true,
      message: Language.actionModal.checkingDefinitlyDeleted,
      status: null,
      title: Language.actionModal.caution,
    });
  };

  const handleGetPdfBooking = async ({ docType = "contract" }) => {
    setActivityIndicator({
      isOpen: true,
      message: Language.contractDetails.getPdfInProgress,
    });
    const {
      data: { base64Pdf },
      status,
    } = await Booking?.GetPdfBooking({
      docType,
      idBooking,
      token,
    });
    const downloadLink = document.createElement("a");
    const fileName = `${idBooking}.pdf`;
    downloadLink.href = base64Pdf;
    downloadLink.download = fileName;
    downloadLink.click();
    setActivityIndicator({
      isOpen: false,
      message: null,
    });
  };

  const handleGetCheckingImages = async ({ checking, checkingKey }) => {
    checkingsConfig?.items
      .filter(
        ({ key, type }) =>
          type === "picture" &&
          (checking?.[key]?.value || checking?.[key]?.notes)
      )
      .map(async ({ key }, index) => {
        const axiosResponse = await Axios.get(
          `${process.env.REACT_APP_SERVER_URL}/public/objectByUrl?url=${checking?.[key]?.value}`,
          { responseType: "arraybuffer" }
        );

        let blob = new Blob([axiosResponse.data], {
          type: axiosResponse.headers["content-type"],
        });
        let link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = `${idBooking}_${checkingKey}_${key}.jpg`;

        link.click();
      });
  };

  const DeliveryItem = () => {
    const { key, totalAmount } = delivery || {};

    return key === "agencyPickUp" ? (
      <Typography color={Colors.grey}>
        {Language.contractDetails.agencyPickUp}
      </Typography>
    ) : key === "toDeliver" && totalAmount ? (
      <Typography color={Colors.grey}>
        {`${Language.contractDetails.toDeliver} : ${totalAmount}€`}
      </Typography>
    ) : (
      <Typography color={Colors.grey}>
        {Language.contractDetails.noDeliverySelected}
      </Typography>
    );
  };

  const PriceItem = () => {
    const {
      key: discountKey,
      discountComment,
      value: discountValue,
    } = rentalPrices?.discountList?.[0] || {};

    const priceLabelCatalog = {
      unityPrice: rentModeConfig?.machineUnity,
      dayPrice: "jour",
    };
    const { grossAmount, netDiscount, netAmount, rentalAmount } =
      getDiscountPrices({
        dayPrice: contractDetails?.machine?.rentMode?.dayPrice || 0,
        discountKey: discountKey,
        discountValue: discountValue || 0,
        equipmentsSelected: equipmentsSelected,
        rentalPrices,
        servicesSelected: servicesSelected,
        unityPrice: contractDetails?.machine?.rentMode?.unityPrice || 0,
        delivery,
      });

    const billElements = [
      {
        key: "rentalEl",
        isVisible: true,
        label: `${contractDetails?.rentalPrices?.quantity} ${
          priceLabelCatalog[contractDetails?.rentalPrices?.key]
        }(s) x ${
          contractDetails?.rentalPrices?.[contractDetails?.rentalPrices?.key]
        }€`,
        value: rentalAmount,
      },
      {
        key: "equipmentsEl",
        isVisible: Object.entries(equipmentsSelected)?.length > 0,
        label: Object.entries(equipmentsSelected)
          ?.map(([key, value]) => keyLabelConverter?.[key])
          ?.reduce((acc, curr) => (acc ? `${acc} | ${curr}` : `${curr}`), ""),
        value: Object.entries(equipmentsSelected)
          ?.map(
            ([key, value]) => value?.[contractDetails?.rentalPrices?.key] || 0
          )
          .reduce(
            (acc, curr) =>
              acc + parseFloat(contractDetails?.rentalPrices?.quantity * curr),
            0
          ),
      },
      {
        key: "servicesEl",
        isVisible: Object.entries(servicesSelected)?.length > 0,
        label: Object.entries(servicesSelected)
          ?.map(([key, value]) => keyLabelConverter?.[key])
          ?.reduce((acc, curr) => (acc ? `${acc} | ${curr}` : `${curr}`), ""),
        value: Object.entries(servicesSelected)
          ?.map(([key, value]) => value || 0)
          .reduce((acc, curr) => acc + parseFloat(curr), 0),
      },
      {
        key: "deliveryEl",
        isVisible: delivery?.key === "toDeliver",
        label: "Transport",
        value: parseFloat(delivery?.totalAmount || 0),
      },
    ];

    return (
      <div>
        {billElements?.map(({ key, isVisible, label, value }) =>
          isVisible ? (
            <div
              key={key}
              style={{
                display: "flex",
                justifyContent: "space-between",
                padding: 10,
              }}
            >
              <Typography sx={{ color: Colors.grayText }}>{label}</Typography>
              <Typography
                sx={{
                  color: Colors.grayText,
                }}
              >{`${parseFloat(value)?.toFixed(2)}€`}</Typography>
            </div>
          ) : null
        )}
        <div style={{ display: "flex", flexDirection: "row" }}>
          <div style={{ flex: 2 }}></div>
          <div
            style={{
              flex: 1,
              borderTopColor: Colors.grayText,
              borderTopWidth: 2,
              borderTopStyle: "solid",
              display: "flex",
              flexDirection: "column",
            }}
          >
            {[
              {
                label: "TOTAL BRUT",
                isVisible: netDiscount,
                key: "grossAmount",
                value: grossAmount,
                style: { color: Colors.grayText, fontWeight: "bold" },
              },
              {
                label: "Remise",
                isVisible: netDiscount,
                key: "netDiscount",
                value: netDiscount,
                style: { color: Colors.greyShaded, fontStyle: "italic" },
              },
              {
                label: "TOTAL NET",
                isVisible: true,
                key: "netAmount",
                value: netAmount,
                style: { color: Colors.grayText, fontWeight: "bold" },
              },
            ].map(({ key, isVisible, label, style, value }) =>
              isVisible ? (
                <div
                  key={key}
                  style={{
                    flex: 1,
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <Typography sx={{ ...style }}>{label}</Typography>
                  <Typography sx={{ ...style }}>{`${parseFloat(value)?.toFixed(
                    2
                  )}€ HT`}</Typography>
                </div>
              ) : null
            )}
            {discountComment ? (
              <div>
                <p
                  style={{
                    fontSize: 12,
                    fontStyle: "italic",
                    textAlign: "right",
                    flexWrap: "wrap",
                  }}
                >{`Commentaire remise : ${discountComment || ""}`}</p>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    );
  };

  const SignatureItem = () => {
    return signature ? (
      <img src={signature} alt="signature" width={200} />
    ) : (
      <Typography sx={{ color: Colors.greyShaded }}>
        Aucune signature
      </Typography>
    );
  };
  const LokerItem = () => {
    const _member = members?.find(({ id }) => id === createdBy);
    const _date = format(new Date(createdAt), "dd-MM-yyyy");

    return (
      <Typography color={Colors.grey}>
        {`${Language.contractDetails.createdBy} ${_member?.firstName} ${_member?.lastName} le ${_date}`}
      </Typography>
    );
  };

  const CommentItem = ({ value }) => {
    return value ? (
      <Typography color={Colors.grey}>{`${value}`}</Typography>
    ) : (
      <Typography color={Colors.grey}>{`Aucun commentaire.`}</Typography>
    );
  };
  const BookingCostsItem = ({ data }) => {
    return data?.length ? (
      data
        ?.filter(({ key, label, value }) => value)
        ?.map(({ key, label, value }) => (
          <Typography color={Colors.grey} key={key}>
            {`${label} : ${value}€`}
          </Typography>
        ))
    ) : (
      <Typography color={Colors.grey}>
        {Language.contractDetails.noBookingCostsSelected}
      </Typography>
    );
  };

  const ServicesItem = ({ data }) => {
    const _data = data ? Object.keys(data).filter((el) => data[el]) : [];
    return _data?.length ? (
      _data?.map(
        (key) =>
          data[key] && (
            <Typography color={Colors.grey} key={key}>
              {`${keyLabelConverter[key]} : ${data[key]}€`}
            </Typography>
          )
      )
    ) : (
      <Typography color={Colors.grey}>
        {Language.contractDetails.noServiceSelected}
      </Typography>
    );
  };

  const TabBar = () => {
    const tabBarItems = [
      { key: "booking", label: "Contrat" },
      { key: "checking", label: "Etats des lieux" },
      { key: "attachments", label: "Documents" },
    ];
    return (
      <Box
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          padding: 10,
          borderBottomStyle: "solid",
          borderColor: Colors.greyHighShaded,
          borderWidth: 1,
        }}
      >
        <Tabs
          value={tabBarIndex}
          onChange={(event, value) => setTabBarIndex(value)}
          aria-label="checking-tab"
          textColor="secondary"
          indicatorColor="secondary"
        >
          {tabBarItems?.map(({ key, label }) => (
            <Tab key={key} label={label} />
          ))}
        </Tabs>
        <Box
          style={{
            display: "flex",
            flexDirection: "row",
            alignitems: "center",
            gap: 5,
          }}
        >
          <Typography textAlign="center" color={Colors.grey}>
            {`${_idBooking}`}
          </Typography>
          <Tooltip title={Language.contractDetails.copyTooltip}>
            <ContentCopyIcon
              size="large"
              edge="start"
              aria-label="menu"
              onClick={() => navigator.clipboard.writeText(_idBooking)}
              style={{ cursor: "pointer", color: Colors.green }}
            />
          </Tooltip>
        </Box>
      </Box>
    );
  };

  const AttachmentsPanel = () => {
    const {
      REACT_APP_LOKA_S3_APP_BUCKET,
      REACT_APP_S3_APP_REGION,
      REACT_APP_KEYPREFIX,
      REACT_APP_AWS_ACCESS_KEY_ID,
      REACT_APP_AWS_SECRET_ACCESS_KEY_ID,
    } = process.env;

    const handleUploadFile = async (event) => {
      setActivityIndicator({
        isOpen: true,
        message: Language.contractDetails.addAttachmentInProgress,
      });
      const { files } = event?.target || {};
      AWS.config.update({
        accessKeyId: REACT_APP_AWS_ACCESS_KEY_ID,
        secretAccessKey: REACT_APP_AWS_SECRET_ACCESS_KEY_ID,
      });

      const inspektBucket = new AWS.S3({
        params: { Bucket: REACT_APP_LOKA_S3_APP_BUCKET },
        region: REACT_APP_S3_APP_REGION,
      });
      const _name = `${Date.now()}_${user._id}`;
      const params = {
        ACL: "public-read",
        Body: files?.[0],
        Bucket: `${REACT_APP_LOKA_S3_APP_BUCKET}/${REACT_APP_KEYPREFIX}`,
        Key: _name,
        ContentType: files?.[0]?.type,
      };
      const attachmentUrl = await new Promise((resolve, reject) => {
        inspektBucket.putObject(params).send((err) => {
          fileInputRef.current = null;
          err
            ? resolve(err)
            : resolve(
                `https://loka-prod.s3.eu-west-3.amazonaws.com/UPLOADS/${_name}`
              );
        });
      });

      const _attachments = [
        ...attachments,
        {
          author: `${user.firstName} ${user.lastName}`,
          createdAt: Date.now(),
          file: attachmentUrl,
        },
      ];
      const body = { ...contractDetails, attachments: _attachments };
      const { data, status } = await Booking.Update({
        booking: body,
        token: token,
      });

      const { booking } = data || {};
      handleMergeBooking({ booking });
      status &&
        setActivityIndicator({
          isOpen: false,
          message: null,
        });
    };

    const handleDeleteFile = async (url) => {
      setActivityIndicator({
        isOpen: true,
        message: Language.contractDetails.deleteAttachmentInProgress,
      });

      const _attachments = attachments?.filter(({ file }) => file !== url);
      const body = { ...contractDetails, attachments: _attachments };
      const { data, status } = await Booking.Update({
        booking: body,
        token: token,
      });

      const { booking } = data || {};
      handleMergeBooking({ booking });
      status &&
        setActivityIndicator({
          isOpen: false,
          message: null,
        });
    };

    return (
      <Box style={{ flex: 1 }}>
        <Box
          style={{
            display: "flex",
            flex: 1,
            flexDirection: "column",
            padding: "1vw",
          }}
        >
          <Button
            onClick={() => fileInputRef.current.click()}
            style={{
              display: "flex",
              flex: 1,
              alignSelf: "flex-end",
              flexDirection: "row",
              gap: 5,
              backgroundColor: Colors.green,
              padding: "0.5vw",
              borderRadius: 5,
            }}
          >
            <AddIcon style={{ color: Colors.white }} />
            <Typography style={{ color: Colors.white }}>
              {Language.contractDetails.addAttachment}
            </Typography>
            <input
              ref={fileInputRef}
              style={{ display: "none" }}
              accept=".pdf"
              type="file"
              onChange={handleUploadFile}
            />
          </Button>
          <Box
            style={{
              flex: 1,
              display: "flex",
              flexDirection: "row",
              gap: 15,
              flexWrap: "wrap",
            }}
          >
            {attachments?.length ? (
              attachments?.map(({ author, createdAt, file }) => (
                <Box
                  style={{
                    position: "relative",
                    width: "30%",
                    borderStyle: "solid",
                    borderWidth: 1,
                    borderRadius: 5,
                    padding: "1vw",
                    borderColor: Colors.greyHighShaded,
                    boxShadow: "2px 2px rgba(186,194,201)",
                  }}
                >
                  <Button
                    style={{
                      position: "absolute",
                      right: 0,
                    }}
                    onClick={() => handleDeleteFile(file)}
                  >
                    <DeleteIcon style={{ color: Colors.warning }} />
                  </Button>
                  <Box
                    onClick={() => window.open(file, "_blank")}
                    style={{
                      cursor: "pointer",
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    <Box style={{ padding: "0.5vw" }}>
                      <iframe
                        title={file}
                        style={{ height: 100, width: 100 }}
                        src={file}
                      />
                    </Box>
                    <Box style={{ flex: 3 }}>
                      <Typography
                        style={{ color: Colors.grayText }}
                      >{`${author} - ${new Date(createdAt).toLocaleDateString(
                        "fr-FR",
                        {
                          weekday: "long",
                          year: "numeric",
                          month: "long",
                          day: "numeric",
                        }
                      )}`}</Typography>
                    </Box>
                  </Box>
                </Box>
              ))
            ) : (
              <Box style={{ flex: 1, padding: "1vw" }}>
                <Typography
                  style={{
                    fontStyle: "italic",
                    color: Colors.greyShaded,
                    textAlign: "center",
                  }}
                >
                  {Language.contractDetails.noAttachments}
                </Typography>
              </Box>
            )}
          </Box>
        </Box>
      </Box>
    );
  };

  const CheckingPanel = ({ checking }) => {
    const [activeStep, setActiveStep] = useState(0);

    const handleStepChange = ({ min, max, step }) => {
      ((step > activeStep && step <= max) ||
        (step < activeStep && step >= min)) &&
        setActiveStep(step);
    };

    const urlCheckings = checkingsConfig?.items
      .filter(
        ({ key, type }) =>
          type === "picture" &&
          (checking?.[key]?.value || checking?.[key]?.notes)
      )
      .map(({ key }) => ({
        key,
        notes: checking?.[key]?.notes,
        value: checking?.[key]?.value,
      }));
    const textCheckings = checkingsConfig?.items
      .filter(
        ({ key, type }) =>
          ["slider", "default", "numeric"].includes(type) &&
          checking?.[key]?.value
      )
      .map(({ key, unity }) => ({ key, unity, value: checking?.[key]?.value }));
    const _loker = members?.find(({ id }) => id === checking?.createdBy);

    const _checkerInformations = `${
      Language.contractDetails.createdAtCheck
    } ${format(new Date(checking?.createdAt), "dd/MM/yyyy")} ${
      Language.contractDetails.by
    } ${_loker?.firstName} ${_loker?.lastName}`;

    return (
      <Box>
        {urlCheckings?.length ? (
          <Box>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Button
                color="secondary"
                onClick={() =>
                  handleStepChange({
                    min: 0,
                    max: urlCheckings?.length - 1,
                    step: activeStep - 1,
                  })
                }
              >
                <Typography fontSize={30} fontWeight="bold">
                  {Language.contractDetails.last}
                </Typography>
              </Button>
              <Link
                underline="none"
                href={urlCheckings?.[activeStep]?.value}
                target="_blank"
              >
                <Typography
                  backgroundColor={Colors.green}
                  borderRadius="5px"
                  padding={1}
                  color={Colors.white}
                  style={{ cursor: "pointer", textAlign: "center" }}
                >
                  {keyLabelConverter[urlCheckings?.[activeStep]?.key]}
                </Typography>
              </Link>
              <Button
                color="secondary"
                onClick={(step) =>
                  handleStepChange({
                    min: 0,
                    max: urlCheckings?.length - 1,
                    step: activeStep + 1,
                  })
                }
              >
                <Typography fontSize={30} fontWeight="bold">
                  {Language.contractDetails.next}
                </Typography>
              </Button>
            </Box>
            <Carousel selectedItem={activeStep}>
              {(urlCheckings || []).map(({ key, notes, value }, index) =>
                displayImage({ url: value, notes, index, key })
              )}
            </Carousel>
          </Box>
        ) : (
          <Box
            style={{
              display: "flex",
              flexDirection: "column",
              height: 100,
              justifyContent: "center",
            }}
          >
            <Typography
              style={{
                textAlign: "center",
                fontStyle: "italic",
                color: Colors.greyShaded,
              }}
            >
              {Language.contractDetails.noImageAvailable}
            </Typography>
          </Box>
        )}
        <Divider style={{ margin: "1vw" }} />
        <Box>
          {textCheckings.map(({ key, unity, value }) => {
            return (
              <Box key={key}>
                <Box
                  display="flex"
                  flexDirection="row"
                  justifyContent="flex-start"
                  alignItems="center"
                >
                  <>
                    <Typography
                      fontWeight="bold"
                      color={Colors.grey}
                    >{`${keyLabelConverter[key]}`}</Typography>
                    <Typography color={Colors.grey}>{`: ${value} ${
                      unity || ""
                    }`}</Typography>
                  </>
                </Box>
                {checking[key].notes && (
                  <Box>
                    <Typography
                      fontSize={12}
                      color={Colors.grey}
                      fontStyle="italic"
                    >{`${Language.contractDetails.note} ${checking[key].notes}`}</Typography>
                  </Box>
                )}
              </Box>
            );
          })}
        </Box>
        <Divider style={{ margin: "1vw" }} />
        <Box>
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="flex-start"
            alignItems="center"
            width="100%"
          >
            <Typography
              style={{
                textAlign: "right",
                width: "100%",
                fontStyle: "italic",
              }}
              color={Colors.grey}
            >
              {_checkerInformations}
            </Typography>
          </Box>
        </Box>
      </Box>
    );
  };

  return (
    <Dialog fullScreen open={true}>
      {loading ? (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          flex={1}
        >
          <CircularProgress color="secondary" />
        </Box>
      ) : (
        <>
          {isOpenAddUpBook && (
            <AddUpdateBooking
              discountGrid={discountGrid}
              initialBooking={contractDetails}
              handleClose={() => setIsOpenAddUpBook(false)}
              handleMergeBooking={handleMergeBooking}
              role="updateBooking"
              setSnackBar={(props) => setSnackBar(props)}
            />
          )}
          <Box>
            <AppBar position="static">
              <Toolbar>
                <IconButton
                  size="large"
                  edge="start"
                  color="inherit"
                  aria-label="menu"
                  sx={{ mr: 2 }}
                  onClick={() => setContractDetails(null)}
                >
                  <CloseIcon />
                </IconButton>
                <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                  {customer?.name}
                </Typography>
                {appBarButtonList.map((iconButton) => (
                  <Tooltip title={iconButton.tooltip} key={iconButton.key}>
                    <IconButton
                      size="large"
                      edge="start"
                      color="inherit"
                      aria-label="menu"
                      sx={{ mr: 2 }}
                      disabled={iconButton.restriction}
                      onClick={iconButton.onClick}
                    >
                      {iconButton.icon}
                    </IconButton>
                  </Tooltip>
                ))}
              </Toolbar>
            </AppBar>
          </Box>
          <TabBar />
          <Box
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "flex-start",
            }}
          >
            {tabBarIndex === 0 ? (
              <>
                <Box
                  width="50%"
                  style={{
                    borderWidth: 1,
                    borderColor: Colors.greyHighShaded,
                    borderRightStyle: "solid",
                  }}
                >
                  {[
                    {
                      title: `${Language.contractDetails.loker.toUpperCase()}`,
                      component: <LokerItem />,
                    },
                    {
                      title: customerConfiguration?.label.toUpperCase(),
                      component: customerConfiguration?.items?.map((item) => (
                        <Box flexDirection="row" key={item?.key}>
                          <Typography color={Colors.grey}>
                            {contractDetails?.customer[item?.key] &&
                              `${item.label} : ${
                                contractDetails?.customer[item?.key]
                              }`}
                          </Typography>
                        </Box>
                      )),
                    },
                    {
                      title: `${machineConfig?.label.toUpperCase()}`,
                      component: (
                        <Box
                          display="flex"
                          flex={1}
                          flexDirection="row"
                          justifyContent="space-between"
                          alignItems="center"
                        >
                          <Box flex={3}>
                            {machineConfig?.items?.map((item) => (
                              <Box flexDirection="row" key={item.key}>
                                <Typography color={Colors.grey}>
                                  {machine[item?.key] &&
                                    `${item.label} : ${machine[item?.key]}`}
                                </Typography>
                              </Box>
                            ))}
                          </Box>
                          <Box flex={1} textAlign="center">
                            <img
                              src={
                                pictures?.frontLeft ||
                                pictures?.frontRight ||
                                pictures?.rearLeft ||
                                pictures?.rearRight ||
                                null
                              }
                              width="150px"
                              style={{ boxShadow: "2px 2px 5px grey" }}
                            />
                          </Box>
                        </Box>
                      ),
                    },
                    {
                      title: `${Language.contractDetails.comment.toUpperCase()}`,
                      component: <CommentItem value={comment?.text || null} />,
                    },
                    {
                      title: `${Language.contractDetails.equipments.toUpperCase()}`,
                      component: <EquipmentsItem data={equipmentsSelected} />,
                    },
                    {
                      title: `${Language.contractDetails.services.toUpperCase()}`,
                      component: <ServicesItem data={servicesSelected} />,
                    },
                    {
                      title: `${Language.contractDetails.bookingCosts.toUpperCase()}`,
                      component: <BookingCostsItem data={bookingCosts} />,
                    },
                    {
                      title: `${Language.contractDetails.delivery.toUpperCase()}`,
                      component: <DeliveryItem />,
                    },
                    {
                      title: `${Language.contractDetails.signature.toUpperCase()}`,
                      component: <SignatureItem />,
                    },
                  ].map(({ title, component }, index) => (
                    <Box
                      key={index}
                      id="customer"
                      style={{
                        borderStyle: "solid",
                        borderWidth: 1,
                        borderColor: Colors.greyHighShaded,
                        borderRadius: "1vw",
                        margin: "1vw",
                        padding: "1vw",
                      }}
                    >
                      <Typography
                        variant="subtitle1"
                        color={Colors.green}
                        fontWeight="bold"
                      >
                        {title}
                      </Typography>
                      <Divider />
                      {component}
                    </Box>
                  ))}
                </Box>
                <Box width="50%">
                  {[
                    {
                      title: `${Language.contractDetails.rentalPeriod.toUpperCase()}`,
                      component: (
                        <>
                          <Typography color={Colors.grey}>
                            {`${rentalPrices.quantity} ${
                              Language.contractDetails[rentalPrices.key]
                            } x ${rentalPrices[rentalPrices.key]}€`}
                          </Typography>
                          <Box textAlign="center" padding="2%">
                            <LokaDatePicker
                              isEditable={false}
                              rentalPeriod={rentalPeriod}
                            />
                          </Box>
                        </>
                      ),
                    },
                    {
                      title: `${Language.contractDetails.price.toUpperCase()}`,
                      component: <PriceItem />,
                    },
                  ].map(({ title, component }, index) => (
                    <Box
                      key={index}
                      id="customer"
                      style={{
                        borderStyle: "solid",
                        borderWidth: 1,
                        borderColor: Colors.greyHighShaded,
                        borderRadius: "1vw",
                        margin: "1vw",
                        padding: "1vw",
                      }}
                    >
                      <Typography
                        variant="subtitle1"
                        color={Colors.green}
                        fontWeight="bold"
                      >
                        {title}
                      </Typography>
                      <Divider />
                      {component}
                    </Box>
                  ))}
                </Box>
              </>
            ) : tabBarIndex === 1 ? (
              <Box
                style={{
                  flex: 1,
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                {[
                  {
                    checkingKey: "departure",
                    checkingItems: contractsToStartCheckings,
                    isNotAvailable: [null, undefined].includes(
                      contractsToStartCheckings
                    ),
                  },
                  {
                    checkingKey: "return",
                    checkingItems: contractsToStopCheckings,
                    isNotAvailable: [null, undefined].includes(
                      contractsToStopCheckings
                    ),
                  },
                ].map(({ checkingKey, checkingItems, isNotAvailable }) => (
                  <Box style={{ flex: 1, margin: "1vw", padding: "1vw" }}>
                    <Box
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <Box>
                        <Typography
                          variant="h6"
                          textAlign="center"
                          color={Colors.grey}
                        >
                          {Language.contractDetails?.[checkingKey]}
                        </Typography>
                      </Box>
                      <Box>
                        {[
                          {
                            buttonKey: "deleteChecking",
                            isDisabled:
                              licence !== "manager" ||
                              (checkingKey === "departure" &&
                                contractsToStopCheckings) ||
                              isNotAvailable,
                            onClick: () =>
                              handleDeleteChecking({
                                checkingKey:
                                  checkingKey === "departure"
                                    ? "startChecking"
                                    : "stopChecking",
                              }),
                            title: Language.contractDetails.undoChecking,
                            icon: (props) => <UndoIcon {...props} />,
                          },
                          {
                            buttonKey: "getCheckingImages",
                            isDisabled: isNotAvailable,
                            onClick: () =>
                              handleGetCheckingImages({
                                checking: checkingItems,
                                checkingKey:
                                  checkingKey === "departure"
                                    ? "startChecking"
                                    : "stopChecking",
                              }),
                            title: Language.contractDetails.downloadImages,
                            icon: (props) => <ImageIcon {...props} />,
                          },
                          {
                            buttonKey: "getPdfBooking",
                            isDisabled: isNotAvailable,
                            onClick: () =>
                              handleGetPdfBooking({
                                docType:
                                  checkingKey === "departure"
                                    ? "startChecking"
                                    : "stopChecking",
                              }),
                            title: Language.contractDetails.pdfBooking,
                            icon: (props) => <PictureAsPdfIcon {...props} />,
                          },
                        ].map(
                          ({ buttonKey, isDisabled, onClick, title, icon }) => (
                            <Button
                              key={buttonKey}
                              disabled={isDisabled}
                              onClick={onClick}
                            >
                              <Tooltip title={title}>
                                {icon({
                                  style: {
                                    color: isDisabled
                                      ? Colors.greyShaded
                                      : Colors.green,
                                  },
                                })}
                              </Tooltip>
                            </Button>
                          )
                        )}
                      </Box>
                    </Box>
                    <Box>
                      <Divider />
                      {!isNotAvailable ? (
                        <CheckingPanel checking={checkingItems || {}} />
                      ) : (
                        <Box style={{ padding: "10vh" }}>
                          <Typography
                            style={{
                              color: Colors.greyShaded,
                              textAlign: "center",
                              fontStyle: "italic",
                            }}
                          >
                            Cet état des lieux n'a pas encore été réalisé sur
                            l'application mobile.
                          </Typography>
                        </Box>
                      )}
                    </Box>
                  </Box>
                ))}
              </Box>
            ) : tabBarIndex === 2 ? (
              <AttachmentsPanel />
            ) : null}
          </Box>
          <LokaSnackBar />
        </>
      )}
    </Dialog>
  );
};
export default ContractDetails;
